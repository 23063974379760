import { IRelatedGame } from "../../types/Game";
import { Link } from "react-router-dom";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

type Props = {
  game: IRelatedGame;
};

const BasicCardActions = (props: Props) => {

  const viewGamePage = "/main/games/info/" + encodeURI(props.game.game_slug);

  return (
    <div className="justify-content-between align-items-center">
      <Link to={viewGamePage}>
        <button type="button" className="btn btn-sm button-outline-custom">
          <SportsEsportsIcon /> More...
        </button>
      </Link>
    </div>
  );
};

export default BasicCardActions;
