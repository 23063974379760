import { ReactElement } from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';


type Props = {
    count: number;
    countSize : string;
    text: string;
    textSize: string;
    icon: ReactElement;
    link?: string;
    class?: string; 
};


const Counter = (props: Props) => {

    return (
        <Link style={{ textDecoration: 'none', color: '#dcdcdc' }} to={`/main/games/${props.link}`}> 
            <div className={`${props.class} p-2`}>
                <div style={{fontSize: props.countSize}} className='no-gutters'><CountUp duration={3} end={props.count}/></div>
                <span>{props.icon} </span>
                <span style={{fontSize: props.textSize}} className=' align-middle '>{props.text}</span>
            </div>
        </Link>
    );
};

export default Counter;