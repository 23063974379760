import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import GameService from "../../services/GameService";
import useAlert from "../../hooks/userAlert";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { Button } from "react-bootstrap";
import { IGameImportSummary } from "../../types/GameImport";
import { BackgroundImageOutletContext } from "../layout/MainLayout";
import { setThemeBackground } from "../../utils/Display";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

const ImportGames = () => {
  const origin = "/main";

  const { setAlert } = useAlert();
  const successMessage = "Games imported successfully";
  const errorMessage = "We had a problem while importing the game data";

  const navigate = useNavigate();

  const [file, setFile] = useState<File | null>(null);
  const [status, setStatus] = useState<
    "initial" | "uploading" | "success" | "fail"
  >("initial");
  const [operation, setOperation] = useState<string>("import");
  const [summary, setSummary] = useState<IGameImportSummary | null>(null);

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  useEffect(() => {
    setThemeBackground(setBackgroundImage);
    // eslint-disable-next-line
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOperation((event.target as HTMLInputElement).value);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setStatus("initial");
      setFile(e.target.files[0]);
    }
  };

  const closeForm = async (e: any) => {
    navigate(origin);
  };

  const handleFileImport = async () => {
    if (file) {
      setStatus("uploading");

      const formData = new FormData();
      formData.append("file", file);

      try {
        const result = await GameService.processFile(formData, operation);
        setSummary(result.data);
        setAlert(successMessage, "success");
        console.log(result);
        setStatus("success");
      } catch (error) {
        handleResponseError(error, errorMessage);
        setStatus("fail");
      }
    }
  };

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const Result = ({ status }: { status: string }) => {
    if (status === "success") {
      return <p>✅ Import process completed</p>;
    } else if (status === "fail") {
      return <p>❌ Import import failed.</p>;
    } else if (status === "uploading") {
      return <p>⏳ Processing selected file...</p>;
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <div className="row mb-1">
        <h6 className="page-header">Import Games from CSV</h6>
      </div>
      <form>
        <div className="row pt-1 px-1 mb-2">
          <div className="form-group">
            <SportsEsportsIcon sx={{ marginRight: 0.5 }}/>
            <label>Game Operation</label>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={operation}
              onChange={handleChange}
            >
              <FormControlLabel
                value="import"
                control={<Radio />}
                label="Add Games"
              />
              <FormControlLabel value="update" control={<Radio />} label="Update Games" />
            </RadioGroup>
          </div>
        </div>
        <div className="row pt-1 px-1">
          <div className="form-group">
            <UploadFileOutlinedIcon sx={{ marginRight: 0.5 }} />
            <label>CSV File</label>
            <input
              className="form-control"
              type="file"
              id="formFile"
              onChange={(e) => handleFileChange(e)}
            />
          </div>
        </div>
        {file && (
          <section>
            <br />
            <AttachFileOutlinedIcon sx={{ marginRight: 0.5 }} />
            File details:
            <ul>
              <li>Name: {file.name}</li>
              <li>Type: {file.type}</li>
              <li>Size: {file.size} bytes</li>
            </ul>
          </section>
        )}

        <Result status={status} />

        {summary && (
          <>
            <br />
            <h3>Game Import Summary</h3>
            <br />

            <h5>{summary?.success?.length} imported items</h5>
            <ul>
              {summary?.success?.map((item) => {
                return (
                  <li>
                    ✅ {item.identifier} - {item.status}
                  </li>
                );
              })}
            </ul>

            <h5>{summary?.failed?.length} failed items</h5>
            <ul>
              {summary?.failed?.map((item) => {
                return (
                  <li>
                    ❌ {item.identifier} - {item.status}
                  </li>
                );
              })}
            </ul>
          </>
        )}

        <div className="container mt-5">
          <div className="row px-1 align-items-center">
            <Button
              className="button-custom mb-2"
              variant="contained"
              onClick={handleFileImport}
            >
              START GAMES IMPORT
            </Button>
            <Button
              className="button-outline-custom"
              onClick={(e) => closeForm(e)}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ImportGames;
