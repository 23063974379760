import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import UserMenu from "../menu/UserMenu";
import MenuIcon from '@mui/icons-material/Menu';

type Props = {
  handleDrawerToggle: () => void,
};

const Topbar = (props: Props) => {

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${sizeConfigs.sidebar.width})`,
                md: `calc(100% - ${sizeConfigs.sidebar.width})` },
        ml: { sm: `${sizeConfigs.sidebar.width}`,
              md: sizeConfigs.sidebar.width },
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color
      }}
    >
      <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={props.handleDrawerToggle}
            sx={{ color: colorConfigs.sidebar.bg, mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
          <UserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;