import { IGame, IRelatedCompany } from "../types/Game";
import { ratingsMap, difficultyMap } from "../data/SelectOptions"

const filterGames = ((searchTerm: string, games: IGame[]) => {
  if (!searchTerm) return games;

  if (games.length > 0) {

    const results = [];

    for (const game of games) {
      if (game.game_title.toLowerCase().includes(searchTerm.toLowerCase())) {
        results.push(game)
      }
    }
    return results;
  }
  
  return [];
});

export const findGameDeveloper = ((companies: IRelatedCompany[]) => {
  if (!companies) return "";

  const developer = companies.find((item)=> item.developer)

  if (developer) {
    return developer.name;
  }

  return "";
});

export const findGamePublishers = ((companies: IRelatedCompany[]) => {
  if (!companies) return [];

  return companies.filter((item)=> item.publisher);
});

export const findGameDevelopers = ((companies: IRelatedCompany[]) => {
  if (!companies) return [];

  return companies.filter((item)=> item.developer);
});

export const getRatingLabel = ((rating: string) => {
  if (rating === "0") return "N/A";

  return ratingsMap[rating as keyof typeof ratingsMap];
});

export const getDifficultyLabel = ((difficulty: string) => {
  if (difficulty === "0") return "N/A";

  return difficultyMap[difficulty as keyof typeof difficultyMap];
});

// const filterGames = ((searchTerm: string, games: IGame[]) => {
//     if (!searchTerm) return games;

//     if (games.length > 0) {
//       const attributes = Object.keys(games[0]) as Array<keyof IGame>;

//       const list = [];

//       for (const game of games) {
//         for (const attribute of attributes) {
//           if (attribute === "pk" || attribute === "sk") {
//             continue;
//           }
          
//           const value = game[attribute];

//           if (value) {
//             if ((typeof value === 'string') && value.toLowerCase().includes(searchTerm.toLowerCase())) {
//                 const found = games.find((row) => row.sk === game.sk);
//                 if (found) {
//                     list.push(found);
//                     break;
//                 }
//             } else if (typeof value === 'object') {
//                 const stringArray = value as Array<String>; 
//                 for (const item of stringArray) {
//                     if (item.toLowerCase().includes(searchTerm.toLowerCase())) {
//                         const found = games.find((row) => row.sk === game.sk);
//                         if (found) {
//                             list.push(found);
//                             break;
//                         }
//                     }
//                 }
//             }
//           }              
//         }
//       }
//       return list;
//     }
//     return [];
// });


// const sortGames = (status: string, games: IGame[], ascending: boolean) => {
//   if (status === "completed") {
//     if (ascending) {
//       games.sort(function(a, b) {
//         return (a.game_completed_date < b.game_completed_date) ? -1 : 
//           ((a.game_completed_date > b.game_completed_date) ? 1 : 0);
//       });
//     } else {
//       games.sort(function(a, b) {
//         return (a.game_completed_date > b.game_completed_date) ? -1 : 
//           ((a.game_completed_date < b.game_completed_date) ? 1 : 0);
//       });
//     }
//   }
//   return games;
// };


const utils = {
  filterGames: filterGames,
  //sortGames: sortGames
}

export default utils;