import DashboardPageLayout from "../pages/dashboard/DashboardPageLayout";
import HomePage from "../pages/home/HomePage";
import { RouteType } from "./config";
import UserGamesPageLayout from "../pages/usergames/UserGamesPageLayout";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CabinOutlinedIcon from '@mui/icons-material/CabinOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import VideogameAssetOutlinedIcon from '@mui/icons-material/VideogameAssetOutlined';
import UserGamesPage from "../pages/usergames/UserGamesPage";
import SearchPageLayout from "../pages/search/SearchPageLayout";
import SearchPage from "../pages/search/SearchPage";
import UtilitiesPageLayout from "../pages/utilities/UtilitiesPageLayout";
import GamesImportPage from "../pages/utilities/GamesImportPage";
import GamesExportPage from "../pages/utilities/GamesExportPage";
import ChartsPage from "../pages/dashboard/ChartsPage";
import TablesPage from "../pages/dashboard/TablesPage";
import UserConnectionsPageLayout from "../pages/connections/UserConnectionsPageLayout";
import FindConnectionsPage from "../pages/connections/FindConnectionsPage";
import ListConnectionsPage from "../pages/connections/ListConnectionsPage";
import sessionManager from "../utils/SessionManager";

const canEdit = sessionManager.isAuthUserCurrentProfileOwner();

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <HomePage />,
    state: "home"
  },
  {
    path: "/main/search",
    element: <SearchPageLayout />,
    state: "search",
    sidebarProps: {
      show: true,
      displayText: "M A I N",
      icon: <CabinOutlinedIcon />
    },
    child: [
      {
        path: "/main/search/games",
        element: <SearchPage />,
        state: "search.games",
        sidebarProps: {
          show: true,
          displayText: "Games",
          icon: <VideogameAssetOutlinedIcon />
        },
      }
    ]
  },
  {
    path: "/main/games",
    element: <UserGamesPageLayout />,
    state: "games",
    sidebarProps: {
      show: true,
      displayText: "G A M I N G",
      icon: <SportsEsportsIcon />
    },
    child: [
      {
        path: "/main/games/playing",
        element: <UserGamesPage type="playing" />,
        state: "games.playing",
        sidebarProps: {
          show: true,
          displayText: "Now Playing",
          icon: <PlayCircleOutlinedIcon />
        }
      },
      {
        path: "/main/games/completed",
        element: <UserGamesPage type="completed" />,
        state: "games.completed",
        sidebarProps: {
          show: true,
          displayText: "Completed",
          icon: <EmojiEventsOutlinedIcon />
        }
      },
      {
        path: "/main/games/retired",
        element: <UserGamesPage type="retired" />,
        state: "games.retired",
        sidebarProps: {
          show: true,
          displayText: "Retired",
          icon: <SaveAltIcon />
        }
      },
      {
        path: "/main/games/backlog",
        element: <UserGamesPage type="backlog" />,
        state: "games.backlog",
        sidebarProps: {
          show: true,
          displayText: "Backlog",
          icon: <FormatListNumberedOutlinedIcon />
        }
      },
      {
        path: "/main/games/favourites",
        element: <UserGamesPage type="favourites" />,
        state: "games.favourites",
        sidebarProps: {
          show: true,
          displayText: "Favourites",
          icon: <FavoriteIcon />
        }
      },
      {
        path: "/main/games/total",
        element: <UserGamesPage type="total" />,
        state: "games.total",
        sidebarProps: {
          show: true,
          displayText: "All Games",
          icon: <VideogameAssetOutlinedIcon />
        }
      }
    ]
  },
  {
    path: "/main/connections",
    element: <UserConnectionsPageLayout />,
    state: "connections",
    sidebarProps: {
      show: true,
      displayText: "C O M M U N I T Y",
      icon: <Diversity3OutlinedIcon />
    },
    child: [
      {
        path: "/main/connections",
        element: <ListConnectionsPage />,
        state: "connections",
        sidebarProps: {
          show: true,
          displayText: "Connections",
          icon: <PeopleAltOutlinedIcon />
        },
      },
      {
        path: "/main/connections/finder",
        element: <FindConnectionsPage />,
        state: "connections.finder",
        sidebarProps: {
          show: canEdit,
          displayText: "Find Members",
          icon: <PersonSearchOutlinedIcon />
        },
      },
    ]
  },
  {
    path: "/main/dashboard",
    element: <DashboardPageLayout />,
    state: "dashboard",
    sidebarProps: {
      show: true,
      displayText: "S T A T S",
      icon: <DashboardOutlinedIcon />
    },
    child: [
      {
        path: "/main/dashboard/charts",
        element: <ChartsPage />,
        state: "dashboard.charts",
        sidebarProps: {
          show: true,
          displayText: "Gaming Charts",
          icon: <BarChartOutlinedIcon />
        },
      },
      {
        path: "/main/dashboard/tables",
        element: <TablesPage />,
        state: "dashboard.tables",
        sidebarProps: {
          show: true,
          displayText: "Gaming Tables",
          icon: <FormatListBulletedOutlinedIcon />
        },
      }
    ]
  },
  {
    path: "/main/util",
    element: <UtilitiesPageLayout />,
    state: "util",
    sidebarProps: {
      show: canEdit,
      displayText: "U T I L I T I E S",
      icon: <AutoFixHighOutlinedIcon />
    },
    child: [
      {
        path: "/main/util/import",
        element: <GamesImportPage />,
        state: "util.import",
        sidebarProps: {
          show: canEdit,
          displayText: "Games Import",
          icon: <FileDownloadOutlinedIcon />
        },
      },
      {
        path: "/main/util/export",
        element: <GamesExportPage />,
        state: "util.export",
        sidebarProps: {
          show: canEdit,
          displayText: "Games Export",
          icon: <FileUploadOutlinedIcon />
        },
      }
    ]
  }
];

export default appRoutes;