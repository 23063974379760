import React, {useContext, useRef, useState} from "react";
import DialogPopup from "../components/dialog/DialogPopup";

type UseDialogShowReturnType = {
    show: boolean;
    setShow: (value: boolean) => void;
    onHide: () => void;
}

const useDialogShow = (): UseDialogShowReturnType => {
    const [show, setShow] = useState(false);

    const handleOnHide = () => {
        setShow(false);
    };

    return {
        show,
        setShow,
        onHide: handleOnHide,
    }
};

type DialogContextType = {
    showDialog: (title: string, message: string) => Promise<boolean>;
};

type DialogContextProviderProps = {
    children: React.ReactNode
}

const DialogModalContext = React.createContext<DialogContextType>({} as DialogContextType);

const DialogContextProvider: React.FC<DialogContextProviderProps> = (props) => {
    const {setShow, show, onHide} = useDialogShow();
    const [content, setContent] = useState<{ title: string, message: string} | null>();
    const resolver = useRef<Function>();

    const handleShow = (title: string, message: string): Promise<boolean> => {
        setContent({
            title,
            message
        });
        setShow(true);
        return new Promise(function (resolve) {
            resolver.current = resolve;
        });
    };

    const modalContext: DialogContextType = {
        showDialog: handleShow
    };

    const handleConfirm = () => {
        resolver.current && resolver.current(true);
        onHide();
    };

    const handleCancel = () => {
        resolver.current && resolver.current(false);
        onHide();
    };

    return (
        <DialogModalContext.Provider value={modalContext}>
            {props.children}

            {content &&
                <DialogPopup
                    isOpen= {show}
                    title= {content.title}
                    description= {content.message}
                    onClose={handleCancel}   
                    onConfirm={handleConfirm}  
                />
            }
        </DialogModalContext.Provider>
    )
};

const useDialogModalContext = (): DialogContextType => useContext(DialogModalContext);

export {
    useDialogShow,
    useDialogModalContext,
}

export default DialogContextProvider;