import { IGame } from "../../types/Game";
import { Link, useNavigate } from "react-router-dom";
import { useDialogModalContext } from "../../contexts/DialogContext";
import useAlert from "../../hooks/userAlert";
import GameService from "../../services/GameService";
import sessionManager from "../../utils/SessionManager";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";

type Props = {
  game: IGame;
  type: string;
};

const GameCardActions = (props: Props) => {
  const dialogContext = useDialogModalContext();
  const { setAlert } = useAlert();
  const deletelSuccessMessage = "Game removed successfully";
  const deleteErrorMessage = "We had a problem while removing the game";

  const canEdit = sessionManager.isAuthUserCurrentProfileOwner();

  const navigate = useNavigate();
  const origin = "/main/games/" + props.type;
  const editGame = "/main/games/update/" + encodeURI(props.game.sk);
  const viewGame = "/main/games/view/" + encodeURI(props.game.sk);
  const gameInfo = "/main/games/info/" + encodeURI(props.game.game_slug);

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const handleDelete = async (id: string) => {
    const choice = await dialogContext.showDialog(
      "Please confirm if you want to remove this game",
      "Be aware that any modified game attributes or additional details provided " +
        "(i.e. Completed section, etc) will be lost. " +
        "A game can be either re-added directly from the Game Search or manually. "
    );

    if (choice) {
      try {
        await GameService.deleteGame(id);
        setAlert(deletelSuccessMessage, "success");
        document.location.reload();
        navigate(origin);
      } catch (error: any) {
        handleResponseError(error, deleteErrorMessage);
      }
    }
  };

  return (
    <div className="justify-content-between align-items-center">
      <div className="btn-group">
        <Tooltip title="View Game Info" arrow>
          <Link to={gameInfo}>
            <button type="button" className="btn btn-sm button-outline-custom">
              <InfoOutlinedIcon fontSize="small" />
            </button>
          </Link>
        </Tooltip>
        <Tooltip title="View Game In Collection" arrow>
          <Link to={viewGame}>
            <button type="button" className="btn btn-sm button-outline-custom">
              <SportsEsportsOutlinedIcon fontSize="small" />
            </button>
          </Link>
        </Tooltip>
        {canEdit && (
          <Tooltip title="Edit Game In Collection" arrow>
            <Link to={editGame}>
              <button type="button" className="btn btn-sm button-outline-custom">
                <EditOutlinedIcon fontSize="small" />
              </button>
            </Link>
          </Tooltip>
        )}
        {canEdit && (
          <Tooltip title="Remove Game From Collection" arrow>
            <button
              type="button"
              onClick={(e) => handleDelete(props.game.sk)}
              className="btn btn-sm btn-outline-danger"
            >
              <DeleteOutlinedIcon fontSize="small" />
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default GameCardActions;
