import { IGame } from "../../types/Game";
import VideogameAssetOutlinedIcon from '@mui/icons-material/VideogameAssetOutlined';
import { dateYear } from "../../utils/Formatter";
import { Chip } from "@mui/material";


type Props = {
    game: IGame;
};


const SearchCardDisplay = (props: Props) => {

    return (
        <>
            <div className="title mb-2">
                {props.game.game_title} <span className="release_date">({dateYear(props.game.game_release)})</span>
            </div>
            {props.game.game_parent && 
                <div className="card-label mb-2 d-flex align-items-center">
                    {props.game.game_category} of {props.game.game_parent.game_title} ({dateYear(props.game.game_parent.game_release)})
                </div>
            }   
            <div className="card-label d-flex align-items-center">
                <VideogameAssetOutlinedIcon sx={{marginRight: 0.5}}/><label>Released Platforms</label>
            </div>
            <div className="mb-2">
                {props.game.game_released_platforms?.slice(0, 8).map((value) => (
                    <Chip sx={{margin: 0.3, fontSize: 10}} variant="outlined" size='small' key={value} label={value} />
                ))}
            </div>
        </>
    );
};

export default SearchCardDisplay;