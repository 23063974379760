import { useEffect, useState } from "react";
import GameService from "../../services/GameService";
import { IStats, IItemCount } from "../../types/Stats";
import useAlert from "../../hooks/userAlert";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import VideogameAssetOutlinedIcon from "@mui/icons-material/VideogameAssetOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import GamepadOutlinedIcon from "@mui/icons-material/GamepadOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import { useOutletContext } from "react-router-dom";
import { BackgroundImageOutletContext } from "../../components/layout/MainLayout";
import { setThemeBackground } from "../../utils/Display";

type Props = {};

const ChartsPage = (props: Props) => {
  const noGamingData = "No current gaming data to be displayed.";

  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState({} as IStats);

  const { setAlert } = useAlert();
  const fecthErrorMessage = "We had a problem while fetching gaming charts";

  const COLORS = ["#6EB1D6", "#5293BB", "#3776A1", "#1B5886", "#003A6B"];

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  useEffect(() => {
    setThemeBackground(setBackgroundImage);
    fetchGamingData();
    // eslint-disable-next-line
  }, []);

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const fetchGamingData = async () => {
    try {
      setIsLoading(true);
      const response = await GameService.getGamingStats();
      setStats(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleResponseError(error, fecthErrorMessage);
    }
  };

  const compare = (a: IItemCount, b: IItemCount) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const RenderGameCharts = () => {
    return (
      <>
        <h2 className="page-header">GAMING CHARTS</h2>
        <p className="mb-2" />

        <div>
          <p className="mb-2" />
          <h3 className="page-title">Completed Games</h3>
          <p className="mb-3" />
          <div className="container-fluid">
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <h5 className="page-subtitle">
                  <VideogameAssetOutlinedIcon fontSize="large" /> By Platforms
                </h5>
                {stats.completed_by_platforms?.length ? (
                  <ResponsiveContainer width={"100%"} height={400}>
                    <BarChart
                      width={500}
                      height={300}
                      margin={{ right: 50 }}
                      data={stats.completed_by_platforms?.sort((a, b) => {
                        return parseInt(a.name) - parseInt(b.name);
                      })}
                    >
                      <Bar dataKey="count" fill="#5293BB" />
                      <CartesianGrid />
                      <XAxis
                        dataKey="name"
                        tick={{ fill: "#FFFFFF" }}
                        height={140}
                        angle={45}
                        dx={15}
                        dy={20}
                        minTickGap={-200}
                        axisLine={false}
                      />
                      <YAxis 
                        domain={[0, 100]}
                      />
                      <Tooltip />
                      <Legend />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="row pt-3">
                    <h6>{noGamingData}</h6>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <h5 className="page-subtitle">
                  <CalendarMonthOutlinedIcon fontSize="large" /> By Gaming Years
                </h5>
                {stats.completed_by_year?.length ? (
                  <ResponsiveContainer width="100%" height={400}>
                    <LineChart
                      margin={{ right: 50 }}
                      data={stats.completed_by_year?.sort((a, b) => {
                        return parseInt(a.name) - parseInt(b.name);
                      })}
                    >
                      <Line
                        type="monotone"
                        dataKey="count"
                        stroke="#5293BB"
                        strokeWidth={3}
                      />
                      <CartesianGrid stroke="#ccc" />
                      <XAxis
                        dataKey="name"
                        padding={{ right: 10 }}
                        angle={45}
                        interval={0}
                      />
                      <YAxis 
                        domain={[0, 200]}
                      />
                      <Tooltip />
                      <Legend />
                    </LineChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="row pt-3">
                    <h6>{noGamingData}</h6>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <h5 className="page-subtitle">
                  <SpeedOutlinedIcon fontSize="large" /> By Difficulty
                </h5>
                {stats.completed_by_difficulty?.length ? (
                  <ResponsiveContainer width="100%" height={400}>
                    <PieChart width={400} height={400}>
                      <Pie
                        data={stats.completed_by_difficulty?.sort(compare)}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        label
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="count"
                      >
                        {stats.completed_by_platforms?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="row pt-3">
                    <h6>{noGamingData}</h6>
                  </div>
                )}
              </div>

              <div className="col game-stats-table rounded pt-2 m-2">
                <h5 className="page-subtitle">
                  <StarOutlineOutlinedIcon fontSize="large" /> By Ratings
                </h5>
                {stats.completed_by_ratings?.length ? (
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      margin={{ right: 50 }}
                      data={stats.completed_by_ratings}
                    >
                      <Bar dataKey="count" fill="#5293BB" />
                      <CartesianGrid />
                      <XAxis
                        dataKey="name"
                        tick={{ fill: "#003A6B" }}
                        height={80}
                        angle={45}
                        dx={15}
                        dy={20}
                        minTickGap={-200}
                        axisLine={false}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="row pt-3">
                    <h6>{noGamingData}</h6>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <h5 className="page-subtitle">
                  <GamepadOutlinedIcon fontSize="large" /> By Genres
                </h5>
                {stats.completed_by_genres?.length ? (
                  <ResponsiveContainer width="100%" height={400}>
                    <PieChart width={400} height={400}>
                      <Pie
                        data={stats.completed_by_genres?.sort(compare)}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        label
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="count"
                      >
                        {stats.completed_by_genres?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="row pt-3">
                    <h6>{noGamingData}</h6>
                  </div>
                )}
              </div>

              <div className="col game-stats-table rounded pt-2 m-2">
                <h5 className="page-subtitle">
                  <CategoryOutlinedIcon fontSize="large" /> By Themes
                </h5>
                {stats.completed_by_themes?.length ? (
                  <ResponsiveContainer width="100%" height={400}>
                    <PieChart width={400} height={400}>
                      <Pie
                        data={stats.completed_by_themes?.sort(compare)}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        label
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="count"
                      >
                        {stats.completed_by_themes?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="row pt-3">
                    <h6>{noGamingData}</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          <p className="mb-4" />
          <h3 className="page-title">Backlog Games</h3>
          <p className="mb-3" />
          <div className="container-fluid">
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <h5 className="page-subtitle">
                  <VideogameAssetOutlinedIcon fontSize="large" /> By Platforms
                </h5>
                {stats.backlog_by_platforms?.length ? (
                  <ResponsiveContainer width="100%" height={350}>
                    <BarChart
                      margin={{ right: 50 }}
                      data={stats.backlog_by_platforms?.sort((a, b) => {
                        return parseInt(a.name) - parseInt(b.name);
                      })}
                    >
                      <Bar dataKey="count" fill="#5293BB" />
                      <CartesianGrid />
                      <XAxis
                        dataKey="name"
                        tick={{ fill: "#003A6B" }}
                        height={140}
                        angle={45}
                        dx={15}
                        dy={20}
                        minTickGap={-200}
                        axisLine={false}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="row pt-3">
                    <h6>{noGamingData}</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          <p className="mb-4" />
          <h3 className="page-title">Retired Games</h3>
          <p className="mb-3" />
          <div className="container-fluid">
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <h5 className="page-subtitle">
                  <VideogameAssetOutlinedIcon fontSize="large" /> By Platforms
                </h5>
                {stats.retired_by_platforms?.length ? (
                  <ResponsiveContainer width="100%" height={350}>
                    <BarChart
                      margin={{ right: 50 }}
                      data={stats.retired_by_platforms?.sort((a, b) => {
                        return parseInt(a.name) - parseInt(b.name);
                      })}
                    >
                      <Bar dataKey="count" fill="#5293BB" />
                      <CartesianGrid />
                      <XAxis
                        dataKey="name"
                        tick={{ fill: "#003A6B" }}
                        height={140}
                        angle={45}
                        dx={15}
                        dy={20}
                        minTickGap={-200}
                        axisLine={false}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="row pt-3">
                    <h6>{noGamingData}</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <div className="spinner-grow" role="status" />
      ) : (
        <RenderGameCharts />
      )}
    </div>
  );
};

export default ChartsPage;
