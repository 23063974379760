import { useEffect, useState } from "react";
import GameService from "../../services/GameService";
import { IStats } from "../../types/Stats";
import useAlert from "../../hooks/userAlert";
import VideogameAssetOutlinedIcon from "@mui/icons-material/VideogameAssetOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import GamepadOutlinedIcon from "@mui/icons-material/GamepadOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import { StarBorder } from "@mui/icons-material";
import { Link, useOutletContext } from "react-router-dom";
import { BackgroundImageOutletContext } from "../../components/layout/MainLayout";
import { setThemeBackground } from "../../utils/Display";
import { getDifficultyLabel, getRatingLabel } from "../../utils/GamesUtils";
import { Rating } from "@mui/material";

type Props = {};

const TablesPage = (props: Props) => {
  const ratingEmptyColor = "#aeaeae";
  const noGamingData = "No current gaming data to be displayed.";

  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState({} as IStats);

  const { setAlert } = useAlert();
  const fecthErrorMessage = "We had a problem while fetching gaming stats";

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  useEffect(() => {
    setThemeBackground(setBackgroundImage);
    fetchGamingStats();
    // eslint-disable-next-line
  }, []);

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const fetchGamingStats = async () => {
    try {
      setIsLoading(true);
      const response = await GameService.getGamingStats();
      setStats(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleResponseError(error, fecthErrorMessage);
    }
  };

  const RenderGameStats = () => {
    return (
      <>
        <h2 className="page-header">Gaming Tables</h2>
        <p className="mb-2" />

        <div>
          <p className="mb-2" />
          <h3 className="page-title">Completed Games</h3>
          <p className="mb-3" />
          <div className="container-fluid">
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <TimerOutlinedIcon fontSize="large" /> By Longest Gameplays
                  </h5>
                  {stats.top_10_longest_gameplays?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Game</th>
                          <th scope="col">Title</th>
                          <th className="right" scope="col">
                            Hours
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.top_10_longest_gameplays?.map((item) => (
                          <tr>
                            <td>
                              {
                                <img
                                  src={item.cover}
                                  alt="game cover"
                                  width="40"
                                />
                              }
                            </td>
                            <td className="align-middle">
                              <Link
                                className="link"
                                to={`/main/games/completed/view/${item.id}`}
                              >
                                {" "}
                                {item.title}
                              </Link>
                            </td>
                            <td className="right align-middle">{item.value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <VideogameAssetOutlinedIcon fontSize="large" /> By Platforms
                  </h5>
                  {stats.completed_by_platforms?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Platform</th>
                          <th scope="col">Name</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_platforms?.map((item) => (
                          <tr>
                            <td>
                              {
                                <img
                                  src={item.logo}
                                  alt="platform"
                                  width="54"
                                />
                              }
                            </td>
                            <td className="align-middle">
                              <Link
                                className="link"
                                state={{
                                  byPlatform: item.name,
                                  byPlatformLogo: item.logo,
                                }}
                                to={`/main/games/completed`}
                              >
                                {item.name}
                              </Link>
                            </td>
                            <td className="right align-middle">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <BusinessIcon fontSize="large" /> By Developers
                  </h5>
                  {stats.completed_by_developers?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Company</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_developers?.map((item) => (
                          <tr>
                            <td>
                              <Link
                                className="link"
                                state={{ byCompany: item.company }}
                                to={`/main/games/completed`}
                              >
                                {item.company.name}
                              </Link>
                            </td>
                            <td className="right">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <BusinessIcon fontSize="large" /> By Publishers
                  </h5>
                  {stats.completed_by_publishers?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Company</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_publishers?.map((item) => (
                          <tr>
                            <td>
                              <Link
                                className="link"
                                state={{ byCompany: item.company }}
                                to={`/main/games/completed`}
                              >
                                {item.company.name}
                              </Link>
                            </td>
                            <td className="right">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <StarOutlineOutlinedIcon fontSize="large" /> By Ratings
                  </h5>
                  {stats.completed_by_ratings?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Rating</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_ratings?.map((item) => (
                          <tr>
                            <td className="align-middle">
                              <Link
                                className="link"
                                state={{ byRating: item.name }}
                                to={`/main/games/completed`}
                              >
                                <Rating
                                  name="overall-ratings"
                                  disabled={true}
                                  emptyIcon={
                                    <StarBorder
                                      sx={{ color: ratingEmptyColor }}
                                    />
                                  }
                                  max={10}
                                  value={parseInt(item.name)}
                                />
                                <div className="rating">
                                  {getRatingLabel(item.name)}
                                </div>
                              </Link>
                            </td>
                            <td className="right align-middle">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <SpeedOutlinedIcon fontSize="large" /> By Difficulty
                  </h5>
                  {stats.completed_by_difficulty?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Difficulty</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_difficulty?.map((item) => (
                          <tr>
                            <td className="align-middle">
                              <Link
                                className="link"
                                state={{ byDifficulty: item.name }}
                                to={`/main/games/completed`}
                              >
                                {getDifficultyLabel(item.name)}
                              </Link>
                            </td>
                            <td className="right align-middle">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <GamepadOutlinedIcon fontSize="large" /> By Genres
                  </h5>
                  {stats.completed_by_genres?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Genre</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_genres?.map((item) => (
                          <tr>
                            <td className="align-middle">
                              <Link
                                className="link"
                                state={{ byGenre: item.name }}
                                to={`/main/games/completed`}
                              >
                                {item.name}
                              </Link>
                            </td>
                            <td className="right align-middle">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <CategoryOutlinedIcon fontSize="large" /> By Themes
                  </h5>
                  {stats.completed_by_themes?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Theme</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_themes?.map((item) => (
                          <tr>
                            <td className="align-middle">
                              <Link
                                className="link"
                                state={{ byTheme: item.name }}
                                to={`/main/games/completed`}
                              >
                                {item.name}
                              </Link>
                            </td>
                            <td className="right align-middle">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <CalendarMonthOutlinedIcon fontSize="large" /> By Gaming
                    Years
                  </h5>
                  {stats.completed_by_year?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Year</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.completed_by_year?.map((item) => (
                          <tr>
                            <td>
                              <Link
                                className="link"
                                state={{ byYear: item.name }}
                                to={`/main/games/completed`}
                              >
                                {item.name}
                              </Link>
                            </td>
                            <td className="right">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div>
          <p className="mb-2" />
          <h3 className="page-title">Backlog Games</h3>
          <p className="mb-3" />
          <div className="container-fluid">
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <VideogameAssetOutlinedIcon fontSize="large" /> By Platforms
                  </h5>
                  {stats.backlog_by_platforms?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Platform</th>
                          <th scope="col">Name</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.backlog_by_platforms?.map((item) => (
                          <tr>
                            <td>
                              {
                                <img
                                  src={item.logo}
                                  alt="platform"
                                  width="54"
                                />
                              }
                            </td>
                            <td className="align-middle">
                              <Link
                                className="link"
                                state={{
                                  byPlatform: item.name,
                                  byPlatformLogo: item.logo,
                                }}
                                to={`/main/games/backlog`}
                              >
                                {item.name}
                              </Link>
                            </td>
                            <td className="right align-middle">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div>
          <p className="mb-2" />
          <h3 className="page-title">Retired Games</h3>
          <p className="mb-3" />
          <div className="container-fluid">
            <div className="row">
              <div className="col game-stats-table rounded pt-2 m-2">
                <div className="table-wrapper">
                  <h5 className="page-subtitle">
                    <VideogameAssetOutlinedIcon fontSize="large" /> By Platforms
                  </h5>
                  {stats.retired_by_platforms?.length ? (
                    <table className="table table-hover table-dark table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Platform</th>
                          <th scope="col">Name</th>
                          <th className="right" scope="col">
                            Games
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {stats.retired_by_platforms?.map((item) => (
                          <tr>
                            <td>
                              {
                                <img
                                  src={item.logo}
                                  alt="platform"
                                  width="54"
                                />
                              }
                            </td>
                            <td className="align-middle">
                              <Link
                                className="link"
                                state={{
                                  byPlatform: item.name,
                                  byPlatformLogo: item.logo,
                                }}
                                to={`/main/games/retired`}
                              >
                                {item.name}
                              </Link>
                            </td>
                            <td className="right align-middle">{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="row pt-3">
                      <h6>{noGamingData}</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <div className="spinner-grow" role="status" />
      ) : (
        <RenderGameStats />
      )}
    </div>
  );
};

export default TablesPage;
