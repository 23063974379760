import axios from "axios";
import config from "../aws-config"
import sessionManager from "../utils/SessionManager";

const instance = axios.create({
  baseURL: config.apiGateway.URL,
});

instance.interceptors.request.use((config) => {
  const session = sessionManager.getUserSession();

  if (session) {
    const idToken = session.idToken;
    config.headers['Authorization'] = 'Bearer ' + idToken.jwtToken;
  }
  
  return config;
});

export default instance;