import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import GameService from "../../services/GameService";
import VideogameAssetOutlinedIcon from "@mui/icons-material/VideogameAssetOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Chip, Stack, Tooltip } from "@mui/material";
import { IGame, IRelatedCompany } from "../../types/Game";
import "../video/styles.css";
import useAlert from "../../hooks/userAlert";
import { Tabs, Tab, Button } from "react-bootstrap";
import ImageModal from "../modal/ImageModal";
import { dateYear, formatDateWithMonthName } from "../../utils/Formatter";
import { styled } from "@mui/system";
import {
  findGameDeveloper,
  findGameDevelopers,
  findGamePublishers,
} from "../../utils/GamesUtils";
import { VirtuosoGrid } from "react-virtuoso";
import GameCard from "../card/GameCard";
import BasicCardDisplay from "../card/BasicCardDisplay";
import BasicCardActions from "../card/BasicCardActions";
import YoutubeEmbed from "../video/YoutubeEmbed";
import { BackgroundImageOutletContext } from "../layout/MainLayout";
import { setGameImageBackground } from "../../utils/Display";
import { IUserGame } from "../../types/UserGame";
import appConfigs from "../../configs/appConfigs";
import StatRate from "../stats/StatRate";
import colorConfigs from "../../configs/colorConfigs";

const ViewGame = () => {
  const { id } = useParams();

  const editGame = "/main/games/update/";
  const addGame = "/main/games/add/";
  const viewGamePage = "/main/games/info/";

  const [showFullSummary, setFullSummary] = useState(false);

  const { setAlert } = useAlert();
  const fecthErrorMessage = "We had a problem while fetching the game";
  // const noGamingData = "No current content to be displayed.";

  const navigate = useNavigate();

  const [game, setGame] = useState({} as IGame);
  const [gameUsers, setGameUsers] = useState([] as IUserGame[]);
  const [ownedUsersGame, setOwnedUsersGame] = useState([] as IUserGame[]);
  const [completedUsersGame, setCompletedUsersGame] = useState(
    [] as IUserGame[]
  );
  const [playingUsersGame, setPlayingUsersGame] = useState([] as IUserGame[]);
  const [backlogUsersGame, setBacklogUsersGame] = useState([] as IUserGame[]);
  const [isLoading, setIsLoading] = useState(false);
  const [gamesInCollection, setGamesInCollection] = useState([] as IUserGame[]);

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  const StyledChip = styled(Chip)({
    margin: 3,
  });

  const PointerChip = styled(StyledChip)({
    cursor: "pointer",
  });

  useEffect(() => {
    fetchGame();
    // eslint-disable-next-line
  }, []);

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const fetchGame = async () => {
    try {
      setIsLoading(true);
      const response = await GameService.getServiceGameById(id);
      const gameData = response.data;
      document.title = `${gameData.game_title} | ${appConfigs.mainTitle}`;
      setGame(gameData);
      setGameImageBackground(gameData, setBackgroundImage);

      const gameExternalId = gameData.game_external_id;
      // Check if the game is part of the current user collection
      retrieveGamesFromUserCollection(gameExternalId);
      // Retrieve all users that have this game in their collection
      retrieveAllGameUsers(gameExternalId);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleResponseError(error, fecthErrorMessage);
    }
  };

  const retrieveAllGameUsers = async (gameId: string) => {
    const gameUsers = (await GameService.getUserGamesByGameID(gameId, true))
      .data;
    setGameUsers(gameUsers);
    setOwnedUsersGame(
      gameUsers.filter(
        (userGame: IUserGame) => userGame.game.game_ownership === "Owned"
      )
    );
    setCompletedUsersGame(
      gameUsers.filter(
        (userGame: IUserGame) => userGame.game.game_status === "completed"
      )
    );
    setPlayingUsersGame(
      gameUsers.filter(
        (userGame: IUserGame) => userGame.game.game_status === "playing"
      )
    );
    setBacklogUsersGame(
      gameUsers.filter(
        (userGame: IUserGame) => userGame.game.game_status === "backlog"
      )
    );
  };

  const retrieveGamesFromUserCollection = async (gameId: string) => {
    const games = (await GameService.getUserGamesByGameID(gameId, false)).data;
    setGamesInCollection(games);
  };

  const closeForm = async (e: any) => {
    navigate(-1);
  };

  const openAddGame = async (e: any) => {
    const {
      game_aggregated_rating,
      game_age_ratings,
      game_artworks,
      game_dlcs,
      game_series,
      game_release_status,
      game_franchise,
      game_modes,
      game_player_perspectives,
      game_similar_games,
      game_summary,
      game_storyline,
      game_videos,
      game_websites,
      game_ports,
      game_remakes,
      game_remasters,
      game_bundles,
      ...gameToBeAdded
    } = game;

    gameToBeAdded["game_progress_percentage"] = "0";
    gameToBeAdded["game_rating"] = "0";
    gameToBeAdded["game_rating_visual"] = "0";
    gameToBeAdded["game_rating_audio"] = "0";
    gameToBeAdded["game_rating_gameplay"] = "0";
    gameToBeAdded["game_rating_story"] = "0";
    gameToBeAdded["game_rating_control"] = "0";
    gameToBeAdded["game_difficulty"] = "0";
    gameToBeAdded["game_duration"] = "0";
    gameToBeAdded["game_duration_minutes"] = "0";

    navigate(addGame, { state: { game: gameToBeAdded } });
  };

  const openEditMode = async (id: string | undefined) => {
    navigate(editGame + id);
  };

  const gameBadges = (status: string) => {
    return (
      <>
        <span className="p-1">
          {status === "playing" && (
            <Tooltip title="Now Playing" arrow>
              <PlayCircleOutlinedIcon />
            </Tooltip>
          )}
          {status === "completed" && (
            <Tooltip title="Completed" arrow>
              <EmojiEventsOutlinedIcon />
            </Tooltip>
          )}
          {status === "retired" && (
            <Tooltip title="Retired" arrow>
              <SaveAltIcon />
            </Tooltip>
          )}
          {status === "backlog" && (
            <Tooltip title="Backlog" arrow>
              <FormatListNumberedOutlinedIcon />
            </Tooltip>
          )}
        </span>
      </>
    );
  };

  const RenderGameView = () => {
    return (
      <>
        <div className="container">
          <section id="header" className="game-solid-background">
            <div className="container p-4">
              <div className="row">
                <div className="col col-lg-3">
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ImageModal imageURL={game.game_cover_url} />
                    <div className="row justify-content-center mt-1">
                      {gamesInCollection.map((item) => (
                        <Button
                          className="btn btn-sm button-outline-custom-invert m-1"
                          style={{ width: 200 }}
                          onClick={(e) => openEditMode(item.game?.sk)}
                        >
                          <EditOutlinedIcon />{" "}
                          <img
                            className="platform-logo-button"
                            src={item.game?.game_platform_logo_url}
                            alt="platform logo"
                            width={25}
                            height={25}
                            loading="lazy"
                          />{" "}
                          {item.game?.game_status.toUpperCase()}
                        </Button>
                      ))}

                      {game.game_released_platforms?.length >
                        gamesInCollection.length && (
                        <Button
                          className="btn btn-sm button-outline-custom m-1"
                          style={{ width: 200 }}
                          onClick={(e) => openAddGame(e)}
                        >
                          <AddCircleIcon /> ADD TO COLLECTION
                        </Button>
                      )}
                    </div>
                  </Stack>
                </div>
                <div className="col-lg-9">
                  <div className="row d-flex align-items-center">
                    <div className="col-lg-9">
                      <h2 className="game-title display-6 fw-bold text-body-emphasis">
                        {game.game_title}
                      </h2>
                      <h6 className="game-title">
                        {formatDateWithMonthName(game.game_release)}
                      </h6>
                      <h4 className="game-title">
                        {findGameDeveloper(game.game_companies)}
                        &nbsp;
                        {game.game_category && (
                          <Chip
                            className="chip-outline-custom"
                            variant="outlined"
                            size="small"
                            key={game.game_category}
                            label={game.game_category}
                          />
                        )}
                      </h4>
                    </div>
                    <div className="col-lg-3">
                      <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {game.game_aggregated_rating && (
                          <div className="mb-2">
                            <StatRate
                              height={100}
                              width={100}
                              rate={game.game_aggregated_rating}
                              isPercentage={false}
                              minValue={0}
                              maxValue={100}
                              rateFontSize={40}
                              trailColor={colorConfigs.stats.statRateTrail}
                              pathColor={colorConfigs.stats.statRatePath}
                              textColor={colorConfigs.stats.statRateText}
                            />
                          </div>
                        )}
                      </Stack>
                    </div>
                  </div>

                  {game.game_parent && (
                    <div className="card-label mb-2 d-flex align-items-center">
                      {game.game_category} of &nbsp;
                      <Link
                        className="link"
                        reloadDocument
                        to={`${viewGamePage}${game.game_parent.game_slug}`}
                      >
                        {game.game_parent.game_title} (
                        {dateYear(game.game_parent.game_release)})
                      </Link>
                    </div>
                  )}
                  <div className="row d-flex align-items-center">
                    <div className="col-lg-9">
                      <div>
                        {game.game_released_platforms?.map((value) => (
                          <Link
                            className="link"
                            state={{ byPlatform: value }}
                            to={`/main/games/total`}
                          >
                            <PointerChip
                              className="chip-outline-custom"
                              variant="outlined"
                              size="medium"
                              key={value}
                              label={value}
                            />
                          </Link>
                        ))}
                      </div>
                      <div className="mt-2">
                        {game.game_genres
                          ?.concat(game.game_themes)
                          .map((value) => (
                            <Link
                              className="link"
                              state={{ byGenre: value }}
                              to={`/main/games/total`}
                            >
                              <PointerChip
                                className="chip-outline-custom"
                                variant="outlined"
                                size="small"
                                key={value}
                                label={value}
                              />
                            </Link>
                          ))}
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="mt-2">
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {game.game_age_ratings?.map((value) => (
                            <Tooltip title={value.synopsis} arrow>
                              <img
                                src={`${window.location.protocol}//${window.location.host}/resources/age-rating/${value.rating}.png`}
                                alt="game age rating"
                              />
                            </Tooltip>
                          ))}
                        </Stack>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    {game.game_modes?.length > 0 && (
                      <div className="col col-sm-12 col-md-6 col-lg-3">
                        <div className="mb-2 d-flex align-items-center">
                          <VideogameAssetOutlinedIcon
                            sx={{ marginRight: 0.5 }}
                          />
                          <label>Game Modes</label>
                        </div>
                        <div>
                          {game.game_modes?.map((value) => (
                            <PointerChip
                              className="chip-outline-custom"
                              variant="outlined"
                              size="small"
                              key={value}
                              label={value}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {game.game_player_perspectives?.length > 0 && (
                      <div className="col col-sm-12 col-md-6 col-lg-3">
                        <div className="mb-2 d-flex align-items-center">
                          <VideogameAssetOutlinedIcon
                            sx={{ marginRight: 0.5 }}
                          />
                          <label>Player Perspectives</label>
                        </div>
                        <div>
                          {game.game_player_perspectives?.map((value) => (
                            <PointerChip
                              className="chip-outline-custom"
                              variant="outlined"
                              size="small"
                              key={value}
                              label={value}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {game.game_series?.length > 0 && (
                      <div className="col col-sm-12 col-md-6 col-lg-3">
                        <div className="mb-2 d-flex align-items-center">
                          <VideogameAssetOutlinedIcon
                            sx={{ marginRight: 0.5 }}
                          />
                          <label>Series</label>
                        </div>
                        <div>
                          <PointerChip
                            className="chip-outline-custom"
                            variant="outlined"
                            size="small"
                            key={game.game_series}
                            label={game.game_series}
                          />
                        </div>
                      </div>
                    )}
                    {game.game_franchise?.length > 0 && (
                      <div className="col col-sm-12 col-md-6 col-lg-3">
                        <div className="mb-2 d-flex align-items-center">
                          <VideogameAssetOutlinedIcon
                            sx={{ marginRight: 0.5 }}
                          />
                          <label>Franchises</label>
                        </div>
                        <div>
                          <PointerChip
                            className="chip-outline-custom"
                            variant="outlined"
                            size="small"
                            key={game.game_franchise}
                            label={game.game_franchise}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="details">
            <div className="container">
              <div className="row mt-4">
                <Tabs defaultActiveKey="overview" className="nav nav-pills">
                  <Tab eventKey="overview" title="Overview">
                    <section
                      id="game-overview"
                      className="game-solid-background"
                    >
                      <div className="container p-4 mt-4">
                        <div className="row g-0">
                          <span className="game-summary">
                            {game.game_summary?.length > 200 && !showFullSummary
                              ? game.game_summary?.slice(0, 200) + "..."
                              : game.game_summary}
                          </span>
                          <span
                            className="game-summary-link"
                            onClick={() => setFullSummary(!showFullSummary)}
                          >
                            {game.game_summary?.length > 200 && (
                              <>Read {showFullSummary ? "Less" : "More"} </>
                            )}
                          </span>
                        </div>
                        <div className="row g-0 pt-4">
                          <div className="col col-sm-12 col-md-6 col-lg-4">
                            <div className="mb-2 d-flex align-items-center">
                              <SportsEsportsIcon sx={{ marginRight: 0.5 }} />
                              <label>Developers</label>
                            </div>
                            <div>
                              {findGameDevelopers(game.game_companies)?.map(
                                (value: IRelatedCompany) => (
                                  <PointerChip
                                    className="chip-outline-custom"
                                    variant="outlined"
                                    size="small"
                                    key={value.name}
                                    label={value.name}
                                  />
                                )
                              )}
                            </div>
                          </div>
                          <div className="col col-sm-12 col-md-6 col-lg-4">
                            <div className="mb-2 d-flex align-items-center">
                              <BusinessIcon sx={{ marginRight: 0.5 }} />
                              <label>Publishers</label>
                            </div>
                            <div>
                              {findGamePublishers(game.game_companies)?.map(
                                (value: IRelatedCompany) => (
                                  <PointerChip
                                    className="chip-outline-custom"
                                    variant="outlined"
                                    size="small"
                                    key={value.name}
                                    label={value.name}
                                  />
                                )
                              )}
                            </div>
                          </div>

                          {game.game_websites && (
                            <div className="col col-sm-12 col-md-6 col-lg-4">
                              <div className="mb-2 d-flex align-items-center">
                                <LanguageOutlinedIcon
                                  sx={{ marginRight: 0.5 }}
                                />
                                <label>Websites</label>
                              </div>
                              <div>
                                {game.game_websites?.map((value) => (
                                  <Link
                                    className="link"
                                    target="_blank"
                                    to={value.url}
                                  >
                                    <PointerChip
                                      className="chip-outline-custom"
                                      variant="outlined"
                                      size="small"
                                      key={value.category}
                                      label={value.category}
                                    />
                                  </Link>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </section>

                    {game.game_screenshots && (
                      <section
                        id="screenshots"
                        className="game-solid-background"
                      >
                        <div className="container p-4 mt-4">
                          <div className="row no-gutters">
                            <div className="image-gallery-row">
                              {game.game_screenshots
                                ?.slice(0, 4)
                                .map((value) => (
                                  <div className="image-gallery-column">
                                    <img
                                      src={value}
                                      alt="screenshot"
                                      loading="lazy"
                                    />
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                  </Tab>
                  <Tab eventKey="media" title="Media">
                    {game.game_screenshots &&
                      game.game_screenshots?.length > 0 && (
                        <section
                          id="screenshots"
                          className="game-solid-background"
                        >
                          <div className="container p-4 mt-4">
                            <div className="row">
                              <h3 className="section-title">
                                Screenshots ({game.game_screenshots?.length} )
                              </h3>
                            </div>
                            <div className="row no-gutters">
                              <div className="image-gallery-row">
                                {game.game_screenshots?.map((value) => (
                                  <div className="image-gallery-column">
                                    <img
                                      src={value}
                                      alt="screenshot"
                                      loading="lazy"
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                            {/* <div className="row">
                            <div className="row p-2 mt-4">
                              <ImageCarousel items={game.game_screenshots} />
                            </div>
                          </div> */}
                          </div>
                        </section>
                      )}
                    {game.game_artworks && game.game_artworks?.length > 0 && (
                      <section id="artworks" className="game-solid-background">
                        <div className="container p-4 mt-4">
                          <div className="row">
                            <h3 className="section-title">
                              Artworks ({game.game_artworks?.length} )
                            </h3>
                          </div>
                          <div className="row no-gutters">
                            <div className="image-gallery-row">
                              {game.game_artworks?.map((value) => (
                                <div className="image-gallery-column">
                                  <img
                                    src={value}
                                    alt="artwork"
                                    loading="lazy"
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                          {/* <div className="row">
                            <div className="row p-2 mt-4">
                              <ImageCarousel items={game.game_artworks} />
                            </div>
                          </div> */}
                        </div>
                      </section>
                    )}
                    {game.game_videos && game.game_videos?.length > 0 && (
                      <section id="videos" className="game-solid-background">
                        <div className="container p-4 mt-4">
                          <div className="row">
                            <h3 className="section-title">
                              Videos ({game.game_videos?.length} )
                            </h3>
                          </div>
                          <div className="row no-gutters">
                            <div className="image-gallery-row">
                              {game.game_videos?.map((value) => (
                                <div className="image-gallery-column">
                                  <YoutubeEmbed embedId={value} />
                                </div>
                              ))}
                            </div>
                          </div>
                          {/* <div className="row">
                            <div className="row p-2 mt-4">
                              <VideoCarousel items={game.game_videos} />
                            </div>
                          </div> */}
                        </div>
                      </section>
                    )}
                  </Tab>
                  <Tab eventKey="related" title="Related Content">
                    {game.game_bundled_games?.length > 0 && (
                      <section id="ports" className="game-solid-background">
                        <div className="container p-4 mt-4">
                          <div className="row">
                            <h3 className="section-title">
                              Bundled Games ({game.game_bundled_games?.length} )
                            </h3>
                          </div>
                          <VirtuosoGrid
                            useWindowScroll
                            listClassName="row no-gutters"
                            itemClassName="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-2"
                            data={game.game_bundled_games}
                            itemContent={(index, game) => (
                              <GameCard
                                game={game}
                                display={<BasicCardDisplay game={game} />}
                                action={<BasicCardActions game={game} />}
                              />
                            )}
                          />
                        </div>
                      </section>
                    )}
                    {game.game_dlcs?.length > 0 && (
                      <section id="ports" className="game-solid-background">
                        <div className="container p-4 mt-4">
                          <div className="row">
                            <h3 className="section-title">
                              DLCS ({game.game_dlcs?.length} )
                            </h3>
                          </div>
                          <VirtuosoGrid
                            useWindowScroll
                            listClassName="row no-gutters"
                            itemClassName="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-2"
                            data={game.game_dlcs}
                            itemContent={(index, game) => (
                              <GameCard
                                game={game}
                                display={<BasicCardDisplay game={game} />}
                                action={<BasicCardActions game={game} />}
                              />
                            )}
                          />
                        </div>
                      </section>
                    )}
                    {game.game_remakes?.length > 0 && (
                      <section id="ports" className="game-solid-background">
                        <div className="container p-4 mt-4">
                          <div className="row">
                            <h3 className="section-title">
                              Remakes ({game.game_remakes?.length} )
                            </h3>
                          </div>
                          <VirtuosoGrid
                            useWindowScroll
                            listClassName="row no-gutters"
                            itemClassName="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-2"
                            data={game.game_remakes}
                            itemContent={(index, game) => (
                              <GameCard
                                game={game}
                                display={<BasicCardDisplay game={game} />}
                                action={<BasicCardActions game={game} />}
                              />
                            )}
                          />
                        </div>
                      </section>
                    )}
                    {game.game_remasters?.length > 0 && (
                      <section id="ports" className="game-solid-background">
                        <div className="container p-4 mt-4">
                          <div className="row">
                            <h3 className="section-title">
                              Remasters ({game.game_remasters?.length} )
                            </h3>
                          </div>
                          <VirtuosoGrid
                            useWindowScroll
                            listClassName="row no-gutters"
                            itemClassName="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-2"
                            data={game.game_remasters}
                            itemContent={(index, game) => (
                              <GameCard
                                game={game}
                                display={<BasicCardDisplay game={game} />}
                                action={<BasicCardActions game={game} />}
                              />
                            )}
                          />
                        </div>
                      </section>
                    )}
                    {game.game_bundles?.length > 0 && (
                      <section id="ports" className="game-solid-background">
                        <div className="container p-4 mt-4">
                          <div className="row">
                            <h3 className="section-title">
                              Bundled In ({game.game_bundles?.length} )
                            </h3>
                          </div>
                          <VirtuosoGrid
                            useWindowScroll
                            listClassName="row no-gutters"
                            itemClassName="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-2"
                            data={game.game_bundles}
                            itemContent={(index, game) => (
                              <GameCard
                                game={game}
                                display={<BasicCardDisplay game={game} />}
                                action={<BasicCardActions game={game} />}
                              />
                            )}
                          />
                        </div>
                      </section>
                    )}
                    {game.game_ports?.length > 0 && (
                      <section id="ports" className="game-solid-background">
                        <div className="container p-4 mt-4">
                          <div className="row">
                            <h3 className="section-title">
                              Ports ({game.game_ports?.length} )
                            </h3>
                          </div>
                          <VirtuosoGrid
                            useWindowScroll
                            listClassName="row no-gutters"
                            itemClassName="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-2"
                            data={game.game_ports}
                            itemContent={(index, game) => (
                              <GameCard
                                game={game}
                                display={<BasicCardDisplay game={game} />}
                                action={<BasicCardActions game={game} />}
                              />
                            )}
                          />
                        </div>
                      </section>
                    )}
                    {game.game_similar_games?.length > 0 && (
                      <section id="ports" className="game-solid-background">
                        <div className="container p-4 mt-4">
                          <div className="row">
                            <h3 className="section-title">
                              Similar Games ({game.game_similar_games?.length} )
                            </h3>
                          </div>
                          <VirtuosoGrid
                            useWindowScroll
                            listClassName="row no-gutters"
                            itemClassName="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-2"
                            data={game.game_similar_games}
                            itemContent={(index, game) => (
                              <GameCard
                                game={game}
                                display={<BasicCardDisplay game={game} />}
                                action={<BasicCardActions game={game} />}
                              />
                            )}
                          />
                        </div>
                      </section>
                    )}
                  </Tab>
                  <Tab eventKey="collections" title="Users Collections">
                    {gameUsers.length > 0 && (
                      <section id="users" className="game-solid-background">
                        <div className="container p-4 mt-4">
                          <Tabs
                            defaultActiveKey="owned"
                            className="nav nav-pills"
                          >
                            <Tab eventKey="owned" title="Owned">
                              <table className="table table-hover table-dark table-striped">
                                <tbody>
                                  {ownedUsersGame.map((item) => (
                                    <tr>
                                      <td width={30}>
                                        <img
                                          src={item.user?.profile_image_url}
                                          alt="user profile"
                                          width="54"
                                        />
                                      </td>
                                      <td className="align-middle">
                                        {item.user?.display_name}
                                      </td>
                                      <td className="align-middle" width={30}>
                                        <img
                                          src={
                                            item.game?.game_platform_logo_url
                                          }
                                          alt="game platform logo"
                                          width="54"
                                        />
                                      </td>
                                      <td className="align-middle">
                                        {item.game?.game_platform}
                                      </td>
                                      <td className="align-middle">
                                        {gameBadges(item.game?.game_status)}{" "}
                                        {item.game?.game_status.toUpperCase()}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Tab>
                            <Tab eventKey="completed" title="Completed">
                              <table className="table table-hover table-dark table-striped">
                                <tbody>
                                  {completedUsersGame.map((item) => (
                                    <tr>
                                      <td width={30}>
                                        <img
                                          src={item.user?.profile_image_url}
                                          alt="user profile"
                                          width="54"
                                        />
                                      </td>
                                      <td className="align-middle">
                                        {item.user?.display_name}
                                      </td>
                                      <td className="align-middle" width={30}>
                                        <img
                                          src={
                                            item.game?.game_platform_logo_url
                                          }
                                          alt="game platform logo"
                                          width="54"
                                        />
                                      </td>
                                      <td className="align-middle">
                                        {item.game?.game_platform}
                                      </td>
                                      <td className="align-middle">
                                        {gameBadges(item.game?.game_status)}{" "}
                                        {item.game?.game_status.toUpperCase()}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Tab>
                            <Tab eventKey="playing" title="Playing">
                              <table className="table table-hover table-dark table-striped">
                                <tbody>
                                  {playingUsersGame.map((item) => (
                                    <tr>
                                      <td width={30}>
                                        <img
                                          src={item.user?.profile_image_url}
                                          alt="user profile"
                                          width="54"
                                        />
                                      </td>
                                      <td className="align-middle">
                                        {item.user?.display_name}
                                      </td>
                                      <td className="align-middle" width={30}>
                                        <img
                                          src={
                                            item.game?.game_platform_logo_url
                                          }
                                          alt="game platform logo"
                                          width="54"
                                        />
                                      </td>
                                      <td className="align-middle">
                                        {item.game?.game_platform}
                                      </td>
                                      <td className="align-middle">
                                        {gameBadges(item.game?.game_status)}{" "}
                                        {item.game?.game_status.toUpperCase()}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Tab>
                            <Tab eventKey="backlog" title="Backlog">
                              <table className="table table-hover table-dark table-striped">
                                <tbody>
                                  {backlogUsersGame.map((item) => (
                                    <tr>
                                      <td width={30}>
                                        <img
                                          src={item.user?.profile_image_url}
                                          alt="user profile"
                                          width="54"
                                        />
                                      </td>
                                      <td className="align-middle">
                                        {item.user?.display_name}
                                      </td>
                                      <td className="align-middle" width={30}>
                                        <img
                                          src={
                                            item.game?.game_platform_logo_url
                                          }
                                          alt="game platform logo"
                                          width="54"
                                        />
                                      </td>
                                      <td className="align-middle">
                                        {item.game?.game_platform}
                                      </td>
                                      <td className="align-middle">
                                        {gameBadges(item.game?.game_status)}{" "}
                                        {item.game?.game_status.toUpperCase()}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Tab>
                          </Tabs>
                        </div>
                      </section>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </section>
        </div>
        <div className="container mt-4">
          <div className="row px-1 align-items-center">
            <Button
              className="button-outline-custom"
              onClick={(e) => closeForm(e)}
            >
              CLOSE
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {isLoading ? (
        <div className="spinner-grow" role="status" />
      ) : (
        <RenderGameView />
      )}
    </div>
  );
};

export default ViewGame;
