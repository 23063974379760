import { useNavigate, useOutletContext } from "react-router-dom";
import GameService from "../../services/GameService";
import useAlert from "../../hooks/userAlert";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { IAccount } from "../../types/Account";
import sessionManager from "../../utils/SessionManager";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import FavoriteIcon from "@mui/icons-material/Favorite";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import SelectOptions from "../../data/SelectOptions";
import { BackgroundImageOutletContext } from "../layout/MainLayout";
import { setThemeBackground } from "../../utils/Display";

const UpdateAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [account, setAccount] = useState({} as IAccount);

  const { setAlert } = useAlert();
  const fecthErrorMessage =
    "We had a problem while fetching your account details";
  const successMessage = "Account details updated successfully";
  const errorMessage = "We had a problem while updating your account details";

  const navigate = useNavigate();

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  useEffect(() => {
    setThemeBackground(setBackgroundImage);
    fetchUserAccount();
    // eslint-disable-next-line
  }, []);

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const fetchUserAccount = async () => {
    try {
      setIsLoading(true);
      const response = await GameService.getUserProfile();
      setAccount(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleResponseError(error, fecthErrorMessage);
    }
  };

  const closeForm = async (e: any) => {
    navigate(-1);
  };

  const onChange = (e: any) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const onChangeDisplayName = (e: any) => {
    const name = e.target.value as string;
    setAccount({
      ...account,
      [e.target.name]: name,
      search_display_name: name.toLowerCase(),
    });
  };

  const getLocationFlagURL = (location: any) => {
    const country = SelectOptions.countries.find((item) => {
      return item.label === location;
    });
    if (country) {
      return country.flag;
    }
  };

  const onChangeLocation = (e: any) => {
    const name = e.target.value as string;
    const flag = getLocationFlagURL(e.target.value);
    setAccount({ ...account, [e.target.name]: name, location_image_url: flag });
  };

  const applySettings = async (e: any) => {
    e.preventDefault();
    try {
      if (!account.user_provided_details) {
        account.user_provided_details = true;
      }
      await GameService.upsertUserProfile(account);

      const userId = account.pk!.split("#")[1];
      sessionManager.setCurrentUserProfile(userId);
      sessionManager.setAuthenticatedUserProfile(userId);
      setAlert(successMessage, "success");

      navigate("/main");
    } catch (error) {
      handleResponseError(error, errorMessage);
    }
  };

  return (
    <div className="container">
      {isLoading ? (
        <div className="spinner-grow" role="status" />
      ) : (
        <>
          {!account.user_provided_details && (
            <div className="row mb-2 large-text">
              <h6 className="page-welcome">
                Welcome to{" "}
                <span className="filtered-value">The Game Stack</span>
              </h6>
              <p>
                Thank you so much for choosing to start tracking & celebrating
                your gaming experiences with us! <StarOutlinedIcon />
              </p>
              <p>
                To get started, please go ahead and update your user profile
                details below to make it uniquely yours. <FavoriteIcon />
              </p>
              <p>
                Happy Gaming Experiences! <SportsEsportsIcon />
              </p>
              <br />
              <br />
              <hr />
              <br />
              <br />
            </div>
          )}
          <div className="row mb-1">
            <h6 className="page-header">Account Settings</h6>
          </div>
          <form>
            <h2 className="page-title">User Profile</h2>
            <p className="mb-2" />
            <div className="row">
              <div className="col-sm-12 my-2">
                <div className="form-group">
                  <label>Email</label>
                </div>
                <div className="form-group">
                  <span>{account.email}</span>
                </div>
              </div>
              <div className="col-sm-12 my-2">
                <div className="form-group">
                  <label>Gamer Tag</label>
                  <input
                    type="text"
                    name="display_name"
                    value={account.display_name}
                    className="form-control"
                    placeholder="Enter your gamer tag"
                    onChange={onChangeDisplayName}
                  />
                </div>
              </div>
              <div className="col-sm-12 my-2">
                <div className="form-group">
                  <label>Profile Image</label>
                  <input
                    type="text"
                    name="profile_image_url"
                    value={account.profile_image_url}
                    className="form-control"
                    placeholder="Enter your profile image URL"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-sm-12 my-2">
                <div className="form-group">
                  <label>Location</label>
                  <select
                    className="form-select"
                    name="location"
                    value={account.location}
                    id="location"
                    onChange={onChangeLocation}
                  >
                    {SelectOptions.countries.map((option) => {
                      return (
                        <option key={option.value} value={option.label}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-sm-12 my-2">
                <div className="form-group">
                  <label>My Gaming Story</label>
                  <textarea
                    name="about_me"
                    rows={4}
                    value={account.about_me}
                    className="form-control"
                    placeholder="Tell us about your gaming story in a nutshell"
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <div className="container mt-5">
              <div className="row px-1 align-items-center">
                <Button
                  className="button-custom mb-2"
                  onClick={(e) => applySettings(e)}
                >
                  APPLY SETTINGS
                </Button>
                <Button
                  className="button-outline-custom"
                  onClick={(e) => closeForm(e)}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default UpdateAccount;
