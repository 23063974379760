import { CognitoUserSession } from 'amazon-cognito-identity-js';
import GameService from "../services/GameService";
import { IAccount } from '../types/Account';


const getAuthenticatedUserId = () => {
    return localStorage.getItem('authenticatedUserId');
};

const getAuthenticatedUserProfile = () => {
    const stringfiedProfile = localStorage.getItem('authenticatedUserProfile');
    if (stringfiedProfile) {
        return JSON.parse(stringfiedProfile)
    }
};

const getCurrentUserProfile = (): IAccount | undefined => {
    const stringfiedProfile = localStorage.getItem('currentUserProfile');
    if (stringfiedProfile) {
        return JSON.parse(stringfiedProfile)
    }
};

const setCurrentUserProfile = async (userId: string) => {
    const response = await GameService.getUserProfileById(userId);
    if (response) {
        const profile: IAccount = response.data;
        localStorage.setItem('currentUserProfile', JSON.stringify(profile));
        localStorage.setItem('currentUserProfileId', userId);
    }
};

const setAuthenticatedUserProfile = async (userId: string) => {
    const response = await GameService.getUserProfileById(userId);
    if (response) {
        const profile: IAccount = response.data;
        localStorage.setItem('authenticatedUserProfile', JSON.stringify(profile));
    }
};

const getCurrentUserProfileId = () => {
    return localStorage.getItem('currentUserProfileId');
};

const getUserSession = (): CognitoUserSession | undefined => {
    const stringfiedSession = localStorage.getItem('currentSession');
    if (stringfiedSession) {
        return JSON.parse(stringfiedSession);
    }
    return undefined;
};

const setUserSession = async (session: CognitoUserSession) => {
    if (session) {
        localStorage.setItem('currentSession', JSON.stringify(session));
        const idToken = session.getIdToken();
        localStorage.setItem('authenticatedUserId', idToken.payload.sub);
        localStorage.setItem('currentUserProfileId', idToken.payload.sub);
        const response = await GameService.getUserProfile();
        if (response) {
            localStorage.setItem('currentUserProfile', JSON.stringify(response.data));
            localStorage.setItem('authenticatedUserProfile', JSON.stringify(response.data));
        }        
    }
};

const clearUserSession = () => {
    localStorage.clear();
}

const isAuthUserCurrentProfileOwner = () => {
    return getAuthenticatedUserId() === getCurrentUserProfileId();
}

const manager = {
    getAuthenticatedUserId: getAuthenticatedUserId,
    getAuthenticatedUserProfile: getAuthenticatedUserProfile,
    setAuthenticatedUserProfile: setAuthenticatedUserProfile,
    getCurrentUserProfileId: getCurrentUserProfileId,
    getCurrentUserProfile: getCurrentUserProfile,
    setCurrentUserProfile: setCurrentUserProfile,
    getUserSession: getUserSession,
    setUserSession: setUserSession,
    clearUserSession: clearUserSession,
    isAuthUserCurrentProfileOwner: isAuthUserCurrentProfileOwner,
};

export default manager;