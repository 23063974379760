import { ReactElement } from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';


type Props = {
    rate: number;
    text?: string;
    icon?: ReactElement;
    minValue: number;
    maxValue: number;
    pathColor: string;
    trailColor: string;
    textColor: string;
    rateFontSize: number;
    isPercentage: boolean;
    width?: number;
    height?: number;
};

const StatRate = (props: Props) => {

    return (
        <div style={{ width: props.width, height: props.height }}>
            <CircularProgressbarWithChildren 
                minValue={props.minValue}
                maxValue={props.maxValue}
                value={props.rate} 
                styles={buildStyles({
                    pathColor: props.pathColor,
                    trailColor: props.trailColor,
                })}
            >
                <span>
                <span style={{color: props.textColor, fontSize: props.rateFontSize, fontWeight: 'bold'}}>{props.rate}</span>
                {props.isPercentage && <span style={{color: props.textColor, fontSize: 25, fontWeight: 'bold'}}>%</span>}
                </span>
                {props.icon}
                <strong style={{color: props.textColor, fontSize: 12}}>{props.text}</strong>
            </CircularProgressbarWithChildren>
        </div>
    );
};

export default StatRate;