import { useNavigate, useOutletContext } from "react-router-dom";
import GameService from "../../services/GameService";
import useAlert from "../../hooks/userAlert";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BackgroundImageOutletContext } from "../layout/MainLayout";
import { setThemeBackground } from "../../utils/Display";

const ExportGames = () => {
  const origin = "/main";

  const { setAlert } = useAlert();
  const successMessage = "Games exported successfully";
  const errorMessage = "We had a problem while exporting the game data";

  const navigate = useNavigate();

  const [status, setStatus] = useState<
    "initial" | "exporting" | "success" | "fail"
  >("initial");

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  useEffect(() => {
    setThemeBackground(setBackgroundImage);
    // eslint-disable-next-line
  }, []);

  const closeForm = async (e: any) => {
    navigate(origin);
  };

  const handleFileExport = async () => {
    try {
      setStatus("exporting");
      const result = await GameService.requestFile();

      // Applied the hack below to force the csv file from the axios response to be downloaded by the browser
      // https://medium.com/@drevets/you-cant-prompt-a-file-download-with-the-content-disposition-header-using-axios-xhr-sorry-56577aa706d6

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileDateTime = new Date().toISOString();
      link.setAttribute("download", "exported_games_" + fileDateTime + ".csv");
      document.body.appendChild(link);
      link.click();

      setAlert(successMessage, "success");
      setStatus("success");
      console.log(result);
    } catch (error) {
      setStatus("fail");
      handleResponseError(error, errorMessage);
    }
  };

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const Result = ({ status }: { status: string }) => {
    if (status === "success") {
      return <p>✅ Games exported successfully.</p>;
    } else if (status === "fail") {
      return <p>❌ Games export failed.</p>;
    } else if (status === "exporting") {
      return <p>⏳ Exporting all games...</p>;
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <div className="row mb-1">
        <h6 className="page-header"> Export All Games to CSV</h6>
      </div>
      <form>
        <Result status={status} />

        <div className="container mt-5">
          <div className="row px-1 align-items-center">
            <Button className="button-custom mb-2" onClick={handleFileExport}>
              DOWNLOAD GAMES
            </Button>
            <Button
              className="button-outline-custom"
              onClick={(e) => closeForm(e)}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ExportGames;
