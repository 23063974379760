const assets = {
    images: {
        logo: require("./images/logo-png.png"),
        logoTransparent: require("./images/logo-transparent-png.png"),
        noCover: require("./images/tgs-no-cover-image.png"),
        partnerLogo: require("./images/IGDB_logo.svg.png"),
        pattern: require("./images/japanese-geometric-cloud-50.jpg"),
    },
    carousel: {
        homePage: require("./images/carousel/home-page.png"),
        viewGame: require("./images/carousel/view-game.png"),
        chartsPage: require("./images/carousel/charts-page.png"),
        gamesCompleted: require("./images/carousel/games-completed.png"),
        searchPage: require("./images/carousel/search-results-page.png"),
        tablesPage: require("./images/carousel/tables-page.png"),
    },
    themes : {
        castlevania: require("./images/themes/castlevania.jpg"),
        metroid: require("./images/themes/metroid.jpg"),
        hades: require("./images/themes/hades.jpg"),
    }
};

export default assets;