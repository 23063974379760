import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import CancelIcon from '@mui/icons-material/Cancel';
import colorConfigs from "../../configs/colorConfigs";
import useAlert from "../../hooks/userAlert";
import { IAccount } from "../../types/Account";
import GameService from "../../services/GameService";
import sessionManager from "../../utils/SessionManager";

type Props = {};

const Register = (props: Props) => {

    const navigate = useNavigate();
    const { setAlert } = useAlert();

    const [state, setState] = useState(
        {
            email: '', 
            password: '', 
            confirmPassword: '',
            confirmationCode: '',
            newUser: false
        });

    const onChange = (e: any) => {
        setState({...state, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

		try {
			const newUser = await Auth.signUp({
				username: state.email,
				password: state.password
			});
            if (newUser) {
                setState({...state, newUser: true });
            }			
		} catch (err: any) {
			setAlert(err.message, 'error');
		}
	};

    const handleConfirmationSubmit = async (e: any) => {
		e.preventDefault();

		try {
			await Auth.confirmSignUp(state.email, state.confirmationCode);
			await Auth.signIn(state.email, state.password);

            const session =  await Auth.currentSession();
            sessionManager.setUserSession(session);
            await createUserProfile();

			navigate("/main/account/update");
		} catch (err: any) {
			setAlert(err.message, 'error');
		}
	};

    const createUserProfile = async () => {
        const accountProfile: IAccount = {
            email: state.email,
            display_name: '',
            search_display_name: '',
            profile_image_url: 'https://www.thegamestack.com/arcade.png',
            location: '',
            location_image_url: '',
            about_me: '',
            last_signin: new Date().toISOString(),
            last_signout: '',
            user_provided_details: false
        };
        await GameService.upsertUserProfile(accountProfile);
        GameService.generateGamingStats();

        const userId = sessionManager.getAuthenticatedUserId();
        if (userId) {
            sessionManager.setCurrentUserProfile(userId);
            sessionManager.setAuthenticatedUserProfile(userId);
        }
    }

    const validateForm = () => {
		return (
			state.email.length > 0 &&
			state.password.length > 0 &&
			state.password === state.confirmPassword
		);
	}

    const validateConfirmationForm = () => {
		return state.confirmationCode.length > 0;
	}

    const renderForm = () => {
        return (            
            <form>
                <div className='mb-2'>
                    <label>Email</label>
                    <input type='text' autoFocus name='email' value={state.email} className='form-control' placeholder='Email address' onChange={onChange} />
                </div>
                <div className='mb-2'>
                    <label>Password</label>
                    <input type='password' name='password' value={state.password} className='form-control' placeholder='Password' onChange={onChange} />
                </div>
                <div className='mb-2'>
                    <label>Confirm Password</label>
                    <input type='password' name='confirmPassword' value={state.confirmPassword} className='form-control' placeholder='Confirm password' onChange={onChange} />
                </div>
                <div className="pt-2">
                    <button type="button" onClick={e => (handleSubmit(e))} disabled={!validateForm()} className="btn button-outline-custom btn-lg">
                        <PersonAddAlt1Icon /> Sign Up
                    </button>
                </div>
            </form>
        )
    }

    const renderConfirmationForm = () => {
        return (
            <form >
                <p>Please check your email for your account verification code</p>
                <div className='mb-2'>
                    <label>Verification Code</label>
                    <input type='text' name='confirmationCode' value={state.confirmationCode} className='form-control' placeholder='Verification code' onChange={onChange} />
                </div>

                <div className="pt-2">
                    <button type="button" onClick={e => (handleConfirmationSubmit(e))} disabled={!validateConfirmationForm()} className="btn button-outline-custom btn-lg">
                        <PersonAddAlt1Icon /> Complete Sign Up
                    </button>
                </div>
            </form>
        )
    }

    return (
        <Box
            className="gradient-background"
            bgcolor={"background.default"} color={"text.primary"}
            padding={2}
            margin={2}
            borderRadius={2}
            sx={{
                width: {xs: 300, sm: 600},
                height: 350,
            }} >
            <Stack direction={"row"} justifyContent="space-between">
                <Typography className="auth-title" variant="h6" mb={2}>
                    Sign Up Now
                </Typography>
                <Link to="/" >
                    <Tooltip title="Close" arrow>
                        <CancelIcon sx={{ color: colorConfigs.sidebar.bg, 
                            "&:hover": { color: colorConfigs.sidebar.hoverBg } }} />
                    </Tooltip>
                </Link>
            </Stack>
            
            {state.newUser ? renderConfirmationForm() : renderForm()}
                
        </Box>        
    );

};

export default Register;