import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import { Avatar, Stack, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import appRoutes from '../../routes/appRoutes';
import SidebarItemCollapse from './SidebarItemCollapse';
import SidebarItem from './SidebarItem';
import sizeConfigs from '../../configs/sizeConfigs';
import colorConfigs from '../../configs/colorConfigs';
import { IAccount } from '../../types/Account';
import sessionManager from "../../utils/SessionManager";

type Props = {
  mobileOpen: boolean,
  handleDrawerToggle: () => void,
};

const Sidebar = (props: Props) => {

  const account: IAccount | undefined = sessionManager.getCurrentUserProfile();

  const drawer = (
    <List disablePadding>
      <Toolbar sx={{ marginBottom: "10px", marginTop: "10px" }}>
        <Stack
          sx={{ width: "100%" }}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
            <Link style={{textDecoration: "none"}} onClick={props.handleDrawerToggle} to="/main">
              <h2 className="app-title-small">The Game Stack</h2>
            </Link>
            <Tooltip title="Active gamer profile">
              {/* <Link style={{textDecoration: "none", color: "black"}} to="/main/account"> */}
                <Avatar src={account?.profile_image_url} alt={account?.display_name} 
                  sx={{ width: 40, height: 40, color: '#000', backgroundColor: "white" }} 
                />
              {/* </Link> */}
            </Tooltip>
            {account?.display_name}
        </Stack>
      </Toolbar>
      {appRoutes.map((route, index) => (
        route.sidebarProps ? (
          route.child ? (
            <SidebarItemCollapse item={route} key={index} handleDrawerToggle={props.handleDrawerToggle} />
          ) : (
            <SidebarItem item={route} key={index} handleDrawerToggle={props.handleDrawerToggle} />
          )
        ) : null
      ))}
    </List>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <div>
        <Drawer
          container={container}
          variant="temporary"
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: sizeConfigs.sidebar.width,
              //backgroundImage: `radial-gradient( circle farthest-corner at 22.4% 21.7%, rgba(4,189,228,1) 0%, rgba(2,83,185,1) 100.2% )`, 
              backgroundColor: colorConfigs.sidebar.bg,
              color: colorConfigs.sidebar.color,
            },
            
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: sizeConfigs.sidebar.width,
              // backgroundImage: `radial-gradient( circle farthest-corner at 22.4% 21.7%, rgba(4,189,228,1) 0%, rgba(2,83,185,1) 100.2% )`,
              backgroundColor: colorConfigs.sidebar.bg,
              color: colorConfigs.sidebar.color
            },
          }}
          open
        >
          {drawer}
        </Drawer>
    </div>
  );
}

export default Sidebar;