import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { IGame } from "../../types/Game";
import { IPlatformOption } from "../../types/Option";
import UserGame from "../../components/forms/UserGame";
import GameService from "../../services/GameService";
import useAlert from "../../hooks/userAlert";

const AddGamePage = () => {

  const { setAlert } = useAlert();

  const errorSelectOptions =
    "We had a problem while fetching select options data";

  const location = useLocation();
  const { state } = location;
  console.log("state", state);

  const [game, setGame] = useState(state.game as IGame);

  const [isLoading, setIsLoading] = useState(false);
  const [platforms, setPlatforms] = useState([] as IPlatformOption[]);

  useEffect(() => {
    fetchPlatforms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPlatforms = async () => {
    try {
      setIsLoading(true);
      const res = await GameService.getPlatforms();
      setPlatforms(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleResponseError(error, errorSelectOptions);
    }
  };

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  return (
    <>
      {isLoading ? (
        <div className="spinner-grow" role="status" />
      ) : (
        <>
          <UserGame
            action="ADD"
            game={game}
            setGame={setGame}
            platforms={platforms}
          />
        </>
      )}
    </>
  );
};

export default AddGamePage;
