import { Outlet, useOutletContext } from "react-router-dom";
import { BackgroundImageOutletContext } from "../../components/layout/MainLayout";

const SearchPageLayout = () => {

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  return (
    <><Outlet context={{ setBackgroundImage } satisfies BackgroundImageOutletContext}/></>
  );
};

export default SearchPageLayout;