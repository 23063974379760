const aws = {
	apiGateway: {
		REGION: 'eu-west-2',
		URL: 'https://18vqmatczf.execute-api.eu-west-2.amazonaws.com/prod/'
	},
	cognito: {
		REGION: 'eu-west-2',
		USER_POOL_ID: 'eu-west-2_Zi52ZsfjI',
		APP_CLIENT_ID: '7m7s4mnchlhn0vddh4v0gr2bdb'
	}	
}

export default aws;
