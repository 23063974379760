import assets from "../assets";
import { IGame } from "../types/Game";

export const setGameImageBackground = (game: IGame, callback: (image: string) => void) => {
  if (game.game_artworks) {
    callback(game.game_artworks[0]);
  } else if (game.game_screenshots) {
    callback(game.game_screenshots[0]);
  } else if (game.game_cover_url) {
    callback(game.game_cover_url);
  } else {
    setThemeBackground(callback);
  }
};

export const setThemeBackground = (callback: (image: string) => void) => {
  callback(assets.themes.hades);
};
