import { IGame, IRelatedGame } from "../../types/Game";
import assets from "../../assets";
// import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { ReactNode } from "react";


type Props = {
    game: IGame | IRelatedGame;
    display: ReactNode;
    action: ReactNode;
};


const GameCard = (props: Props) => {

    const checkImage = (url: string) => {
        if (!url || url.length === 0) {
            return assets.images.noCover;
        }
        return url;
    };

    return (
        <div className="card">
            <div className="front" style={{ backgroundImage: `url(${checkImage(props.game.game_cover_url!)})` }}>
                {/* {props.game.game_favourite &&
                    <div className="large"><FavoriteBorderOutlinedIcon/></div>
                } */}
            </div>
            <div className="back">
                <div>
                    {props.display}

                    {props.action}
                </div>
            </div>
        </div>
    );
};

export default GameCard;