import ListGames from '../../components/forms/ListGames';

type Props = {
  type: string,
};

const UserGamesPage = (props: Props) => {
  return (
    <div><ListGames type={props.type}/></div>
  );
};

export default UserGamesPage;