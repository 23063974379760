import ImportGames from '../../components/forms/ImportGames';

type Props = {};

const GamesImportPage = (props: Props) => {
  
  return (
    <div><ImportGames /></div>
  );
};

export default GamesImportPage;