import { useNavigate, useOutletContext } from "react-router-dom";
import GameService from "../../services/GameService";
import useAlert from "../../hooks/userAlert";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { IAccount } from "../../types/Account";
import { Chip, Tooltip } from "@mui/material";
import { BackgroundImageOutletContext } from "../layout/MainLayout";
import { setThemeBackground } from "../../utils/Display";

const ViewAccount = () => {
  const editAccount = "/main/account/update";

  const [isLoading, setIsLoading] = useState(false);
  const [account, setAccount] = useState({} as IAccount);

  const { setAlert } = useAlert();
  const fecthErrorMessage =
    "We had a problem while fetching your account details";

  const navigate = useNavigate();

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  useEffect(() => {
    setThemeBackground(setBackgroundImage);
    fetchUserAccount();
    // eslint-disable-next-line
  }, []);

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const fetchUserAccount = async () => {
    try {
      setIsLoading(true);
      const response = await GameService.getUserProfile();
      setAccount(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleResponseError(error, fecthErrorMessage);
    }
  };

  const closeForm = async (e: any) => {
    navigate(-1);
  };

  const openEditMode = async (e: any) => {
    navigate(editAccount);
  };

  return (
    <div className="container">
      {isLoading ? (
        <div className="spinner-grow" role="status" />
      ) : (
        <>
          <div className="row mb-1">
            <h6 className="page-header">Account Settings</h6>
          </div>
          <form>
            <h2 className="page-title">User Profile</h2>
            <p className="mb-2" />
            <div className="row">
              <div className="col-sm-12 my-2">
                <div className="form-group">
                  <img
                    src={account.profile_image_url}
                    className="rounded float-left"
                    alt="avatar"
                    width="200"
                  />
                </div>
              </div>
              <div className="col-sm-12 my-2">
                <div className="form-group mb-1">
                  <label>Email</label>
                </div>
                <div className="form-group">
                  <Chip
                    sx={{ fontSize: 12 }}
                    variant="outlined"
                    size="small"
                    label={account.email}
                  />
                </div>
              </div>
              <div className="col-sm-12 my-2">
                <div className="form-group mb-1">
                  <label>Gamer Tag</label>
                </div>
                <div className="form-group">
                  <Chip
                    sx={{ fontSize: 12 }}
                    variant="outlined"
                    size="small"
                    label={account.display_name}
                  />
                </div>
              </div>
              {account.location_image_url ? (
                <div className="col-sm-12 my-2">
                  <div className="form-group">
                    <Tooltip title={account.location}>
                      <img
                        src={account.location_image_url}
                        className="float-left"
                        alt="flag"
                      />
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="col-sm-12 my-2">
                <div className="form-group mb-2">
                  <label>My Gaming Story</label>
                </div>
                <div className="form-group">
                  <span>{account.about_me}</span>
                </div>
              </div>
            </div>
            <div className="container mt-5">
              <div className="row px-1 align-items-center">
                <Button
                  className="button-custom mb-2"
                  onClick={(e) => openEditMode(e)}
                >
                  EDIT
                </Button>
                <Button
                  className="button-outline-custom"
                  onClick={(e) => closeForm(e)}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default ViewAccount;
