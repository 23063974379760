import { Alert, Snackbar } from '@mui/material';
import useAlert from '../../hooks/userAlert';

const AlertPopup = () => {
  const { text, type } = useAlert();

  if (text && type) {
    return (
      <Snackbar
        open={true}
      >
        <Alert
          severity={type}
          sx={{
            position: 'relative',
          }}
        >
          {text}
        </Alert>
      </Snackbar>
    );
  } else {
    return <></>;
  }
};

export default AlertPopup;