import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Logout from '@mui/icons-material/Logout';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import { Auth } from "aws-amplify";
import { useNavigate } from 'react-router-dom';
import useAlert from '../../hooks/userAlert';
import { Divider, Tooltip } from '@mui/material';
import sessionManager from "../../utils/SessionManager";
import { IAccount } from '../../types/Account';
import GameService from "../../services/GameService";

type Props = {};

const UserMenu = (props: Props) => {
  
  const navigate = useNavigate();

  const { setAlert } = useAlert();
  const errorMessage = 'We had a problem while logging out the user session';

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const account: IAccount = sessionManager.getAuthenticatedUserProfile();
  
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async (e: any) => {
    try {
      await Auth.signOut();
      await updateUserProfile();
      sessionManager.clearUserSession();
      navigate("/");
    }
    catch (error) {
      console.log(error);
      setAlert(errorMessage, 'error');
    }
	};

  const handleAccount = async (e: any) => {
    navigate("/main/account")
	};

  const goToUserProfile = async (e: any) => {
    const userId = account.pk!.split('#')[1]; 
    await sessionManager.setCurrentUserProfile(userId);        
    navigate("/main");
    document.location.reload();
  };

  const updateUserProfile = async () => {
		const accountProfile = sessionManager.getAuthenticatedUserProfile();
    if (accountProfile) {
        accountProfile.last_signout = new Date().toISOString();
        await GameService.upsertUserProfile(accountProfile);
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar src={account?.profile_image_url} alt={account?.display_name} 
              sx={{ border: '2px solid #ff9f59', width: 40, height: 40, color: '#000', backgroundColor: "white" }} 
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            background: '#ff9f59',
            color: 'black',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'black',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <MenuItem onClick={goToUserProfile}>
          <ListItemIcon>
          <SportsEsportsOutlinedIcon
             sx={{ color: 'black' }} />
          </ListItemIcon>
          My profile
        </MenuItem>
        <MenuItem onClick={handleAccount}>
          <ListItemIcon>
            <SettingsOutlinedIcon
             sx={{ color: 'black' }} />
          </ListItemIcon>
          Settings
        </MenuItem>
        <Divider sx={{backgroundColor: "black"}}/>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout sx={{ color: 'black' }} />
          </ListItemIcon>
          Logout
        </MenuItem>             
      </Menu>
    </React.Fragment>
  );
}

export default UserMenu;