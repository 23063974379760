import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import LoginIcon from '@mui/icons-material/Login';
import CancelIcon from '@mui/icons-material/Cancel';
import colorConfigs from "../../configs/colorConfigs";
import useAlert from "../../hooks/userAlert";
import sessionManager from "../../utils/SessionManager";
import GameService from "../../services/GameService";

type Props = {};

const Login = (props: Props) => {

    const navigate = useNavigate();
    const { setAlert } = useAlert();

    const [state, setState] = useState({email: "", password: ""});

    const onChange = (e: any) => {
        setState({...state, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e: any) => {
		e.preventDefault();

        try {
			await Auth.signIn(state.email, state.password);
            const session =  await Auth.currentSession();
            await sessionManager.setUserSession(session);
            await updateUserProfile();

			navigate("/main");
            document.location.reload();
		} catch (err: any) {            
            setAlert(err.message, 'error');
		}
	};

    const updateUserProfile = async () => {
		const accountProfile = sessionManager.getAuthenticatedUserProfile();
        if (accountProfile) {
            accountProfile.last_signin = new Date().toISOString();
            await GameService.upsertUserProfile(accountProfile);
            const userId = accountProfile.pk!.split('#')[1]; 
            sessionManager.setAuthenticatedUserProfile(userId);
        }
    };

    const validateForm = () => {
		return (
			state.email.length > 0 &&
			state.password.length > 0
		);
	};

    return (
        <Box
            className="gradient-background"
            bgcolor={"background.default"} color={"text.primary"}
            padding={2}
            margin={2}
            borderRadius={2}
            sx={{
                width: {xs: 300, sm: 600},
                height: 280,
            }} >
            <Stack direction={"row"} justifyContent="space-between">
                <Typography className="auth-title" variant="h6" mb={2}>
                    Sign into your account
                </Typography>
                <Link to="/" >
                    <Tooltip title="Close" arrow>
                        <CancelIcon sx={{ color: colorConfigs.sidebar.bg, 
                            "&:hover": { color: colorConfigs.sidebar.hoverBg } }} />
                    </Tooltip>
                </Link>
            </Stack>
                
            <form>
                <div className='mb-2'>
                    <label>Email</label>
                    <input type='text' autoFocus name='email' className='form-control' placeholder='Email address' onChange={onChange} />
                </div>
                <div className='mb-2'>
                    <label>Password</label>
                    <input type='password' name='password' className='form-control' placeholder='Password' onChange={onChange} />
                </div>
                <div className="pt-2">
                    <button type="button" onClick={e => (handleSubmit(e))} disabled={!validateForm()} className="btn button-outline-custom btn-lg">
                        <LoginIcon /> Log In
                    </button>
                </div>
            </form>
        </Box>
    );
    };

export default Login;