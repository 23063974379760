import ExportGames from '../../components/forms/ExportGames';

type Props = {};

const GamesExportPage = (props: Props) => {
  return (
    <div><ExportGames /></div>
  );
};

export default GamesExportPage;