import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useAlert from '../../hooks/userAlert';
import { Auth } from "aws-amplify";
import sessionManager from "../../utils/SessionManager";

type Props = {};

const ProtectedRoute = (props: Props) => {

    const navigate = useNavigate();
    const { setAlert } = useAlert();

    const isSessionValid = (session: any) => {
      const idToken = session.idToken;
      const expTime = idToken.payload.exp * 1000;
      const currentTime = Date.now();
      return (expTime > currentTime);
    }

    const checkAuthState = async () => {
      const session = sessionManager.getUserSession();
      if (session) {
        if (!isSessionValid(session)) {          
          await Auth.signOut();
          sessionManager.clearUserSession();       
          setAlert("Session expired. Please sign in again.", 'warning');
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    }

    useEffect(() => {
      checkAuthState();
    })

    return <Outlet {...props} />
}

export default ProtectedRoute;