import { Avatar, AvatarGroup, Stack, Tooltip } from "@mui/material";
import { IAccount } from "../../types/Account";
import { IStats } from "../../types/Stats";
import { IAccountConnections } from "../../types/AccountConnections";
import Counter from "../counter/Counter";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";

type Props = {
  account: IAccount | undefined;
  stats: IStats;
  following: IAccountConnections;
};

const counterSize = "25px";
const counterTextSize = "12px";
const counterClass = "fw-bold";

const UserHeader = (props: Props) => {
  const { account, stats, following } = props;

  return (
    <div className="container-fluid no-gutters">
      <div className="row d-flex align-items-end mb-1 pt-0">
        <div className="col-1">
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Avatar
              src={account?.profile_image_url}
              alt={account?.display_name}
              sx={{
                top: "30px",
                width: 100,
                height: 100,
                color: "#000",
                backgroundColor: "white",
                border: "10px solid #539343",
              }}
            />
          </Stack>
        </div>
        <div className="col-1">
          <div className="user-display-name fw-bold text-body-emphasis">
            {account?.display_name}
          </div>
        </div>
        <div className="col-10">
          <AvatarGroup
            max={4}
            total={following.following?.length}
          >
            {following.following?.map((user) => (
              <Tooltip placement="top" title={user.display_name}>
                <Avatar 
                  alt={user.display_name} 
                  src={user.profile_image_url}
                  sx={{
                    backgroundColor: "lightgray"
                  }}
                />
              </Tooltip>
            ))}
          </AvatarGroup>
        </div>
      </div>
      <div className="row">
        <section id="header" className="game-solid-background">
          <div className="container no-gutters">
            <div className="row d-flex align-items-center">
              <div className="col-1"></div>
              <div className="col">
                <Counter
                  link="total"
                  count={props.stats.total}
                  text="Games"
                  icon={<SportsEsportsIcon />}
                  countSize={counterSize}
                  textSize={counterTextSize}
                  class={counterClass}
                />
              </div>
              <div className="col">
                <Counter
                  link="completed"
                  count={stats.completed}
                  text="Completed"
                  icon={<EmojiEventsOutlinedIcon />}
                  countSize={counterSize}
                  textSize={counterTextSize}
                  class={counterClass}
                />
              </div>
              <div className="col">
                <Counter
                  link="playing"
                  count={stats.playing}
                  text="Playing"
                  icon={<PlayCircleOutlinedIcon />}
                  countSize={counterSize}
                  textSize={counterTextSize}
                  class={counterClass}
                />
              </div>
              <div className="col">
                <Counter
                  link="retired"
                  count={stats.retired}
                  text="Retired"
                  icon={<SaveAltIcon />}
                  countSize={counterSize}
                  textSize={counterTextSize}
                  class={counterClass}
                />
              </div>
              <div className="col">
                <Counter
                  link="backlog"
                  count={stats.backlog}
                  text="Backlog"
                  icon={<FormatListNumberedOutlinedIcon />}
                  countSize={counterSize}
                  textSize={counterTextSize}
                  class={counterClass}
                />
              </div>
              <div className="col">
                <Counter
                  link="favourites"
                  count={stats.favourites}
                  text="Favourites"
                  icon={<FavoriteIcon />}
                  countSize={counterSize}
                  textSize={counterTextSize}
                  class={counterClass}
                />
              </div>
              <div className="col-3"></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default UserHeader;
