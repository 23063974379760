import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#ff9f59",
    color: "#000",
    hoverBg: "#FFB27A",
    hoverColor: "#000",
    activeBg: "#FFB27A"
  },
  topbar: {
    bg: "#000",
    color: "#000"
  },
  icon: {
    color: "#B3D9D9",
    hoverColor: "#518C8C",
    deleteColor: "#f2B195",
    deleteHoverColor: "#e61a1a"
  },
  mainBg: colors.grey["100"],
  stats: {
    statRatePath: "#ff9f59",
    statRateText: "#fff",
    statRateTrail: "#000"
  }
};

export default colorConfigs;