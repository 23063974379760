import {  
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, } from "@mui/material";
import { Button } from "react-bootstrap";

type Props = {
    isOpen: boolean,
    title: string,
    description: string,
    onClose: () => void,
    onConfirm: () => void,
};

const DialogPopup = (props: Props) => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"
                sx={{
                    backgroundColor: "black",
                    color: "#ff9f59"
                }}
            >
                {props.title}
            </DialogTitle>
            <DialogContent
                sx={{
                    backgroundColor: "#272727"
                }}
            >
                <DialogContentText id="alert-dialog-description"
                    sx={{
                        color: "white",
                        paddingTop: 2
                    }}
                >
                    {props.description}                
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{
                    backgroundColor: "black"
                }}
            >
                <Button className="button-outline-custom" onClick={props.onClose}>CANCEL</Button>
                <Button className="button-custom" onClick={props.onConfirm}>CONFIRM</Button>
            </DialogActions>
        </Dialog>
    )
};

export default DialogPopup;