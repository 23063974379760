import { Stack, Typography } from '@mui/material';
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined';

type Props = {};

const NotFoundPage = (props: Props) => {
  return (
    <div className='container-fluid justify-content-left'>
      <h2 className="page-header">The Game Stack</h2>
      <p/>
      <div className='container-fluid w-100 border px-3 pt-3 pb-3 rounded'>
        <Stack sx={{ width: "100%" }} direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={0.5} >
          <HeartBrokenOutlinedIcon fontSize='large'/>
          <Typography variant="h5">404 - Resource not found</Typography>
        </Stack>
      </div>
    </div>
  );
};

export default NotFoundPage;