import ViewAccount from '../../components/forms/ViewAccount';

type Props = {};

const AccountPage = (props: Props) => {
  return (
    <div><ViewAccount /></div>
  );
};

export default AccountPage;