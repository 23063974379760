import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import sizeConfigs from "../../configs/sizeConfigs";
import Topbar from "../common/Topbar";
import AlertPopup from "../alert/AlertPopup";
import Sidebar from "../common/Sidebar";
import { useState } from "react";
import assets from "../../assets";
import { styled } from "@mui/system";

export interface BackgroundImageOutletContext {
  setBackgroundImage(url: string): void;
}

const MainLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [backgroundImage, setBackgroundImage] = useState(assets.themes.hades);

  const BoxWrap = styled(Box)({
    position: "relative",
    flexGrow: 1,
    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
    minHeight: "100vh",
    "&:before": {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundImage: `linear-gradient(rgba(0,0,0, 0.3) 0%,rgba(0,0,0, 1) 100%), url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: "fixed",
    },
  });

  const BoxContent = styled(Box)({
    position: "relative",
    margin: 24,
  });

  return (
    <Box sx={{ display: "flex" }}>
      <Topbar handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{ width: { sm: sizeConfigs.sidebar.width }, flexShrink: { sm: 0 } }}
      >
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Box>
      <BoxWrap >
        <BoxContent component="main">
          <Toolbar />
          <AlertPopup />
          <Outlet
            context={
              { setBackgroundImage } satisfies BackgroundImageOutletContext
            }
          />
        </BoxContent>
      </BoxWrap>
    </Box>
  );
};

export default MainLayout;
