export const formatUserName = (name: any) => {
  if (name) {
    return name.slice(-1).toLowerCase() === "s" ? name + "'" : name + "'s";
  }
  return "Your";
};

export const formatDateWithMonthName = (date: any) => {
  if (!date || date === "") {
    return "";
  }
  const year = date.split("-")[0];
  const month = date.split("-")[1] - 1;
  const day = date.split("-")[2];
  const releaseDate = new Date(year, month, day);
  const monthName = releaseDate.toLocaleString("default", { month: "short" });
  return `${monthName} ${day}, ${year}`;
};

export const formatTimestampWithMonthName = (timestamp: any) => {
  if (timestamp === undefined) {
    return "";
  }

  const date = new Date(timestamp);
  const monthName = date.toLocaleString("default", { month: "short" });
  return `${monthName}, ${date.getFullYear()}`;
};

export const formatDate = (date: any) => {
  if (date === undefined || date === "") {
    return "N/A";
  }
  return date.split("-").reverse().join("/");
};

export const dateYear = (date: string | undefined) => {
  if (!date || date.length === 0) {
    return "N/A";
  }
  return date.split("-")[0];
};

export const truncate = (text: string, num: number) => {
  if (text.length > num) {
    return text.slice(0, num) + "...";
  }
  return text;
};

export const convertHoursToYearsMonthsEtc = (hours: number) => {
  
  const units = {
    year: 24 * 60 * 365,
    month: 24 * 60 * 30,
    week: 24 * 60 * 7,
    day: 24 * 60,
    hour: 60,
    minute: 1,
  };

  let hoursInMinutes = hours * 60;
  let result = [];

  for (let [key, value] of Object.entries(units)) {
    var p = Math.floor(hoursInMinutes / value);
    if (p === 1) {
      result.push(p + " " + key);
    }
    if (p >= 2) {
      result.push(p + " " + key + "s");
    }
    hoursInMinutes %= value;
  }

  return result;
};

export const getRatingValue = (rating: any) => {
  if (!rating) {
      return 0;
  }
  return parseInt(rating.split('/')[0].replace('(', ''));
};

export const getRatingDescription = (rating: any) => {
  if (!rating) {
      return 'N/A';
  }
  return rating.split(')')[1].replace(' ', '');
};

export const formatGameDuration = (duration: any) => {
  if (!duration) {
      return 0;
  }
  return parseFloat(duration);
};