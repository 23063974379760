import { useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IGame } from "../../types/Game";
import { IPlatformOption } from "../../types/Option";
import UserGame from "../../components/forms/UserGame";
import GameService from "../../services/GameService";
import useAlert from "../../hooks/userAlert";
import { setGameImageBackground } from "../../utils/Display";
import { BackgroundImageOutletContext } from "../../components/layout/MainLayout";

const ViewGamePage = () => {
  const { id } = useParams();

  const { setAlert } = useAlert();
  const fecthErrorMessage = "We had a problem while fetching the game details";
  const errorSelectOptions =
    "We had a problem while fetching select options data";

  const [game, setGame] = useState({
    game_status: "",
    game_title: "",
    game_themes: [],
    game_companies: [],
    game_genres: [],
    game_released_platforms: [],
    game_platform: "",
    game_platform_logo_url: "",
    game_favourite: false,
    game_summary: "",
    game_storyline: "",
    game_release: "",
    game_cover_url: "",
    game_started_date: "",
    created_at: "",
    updated_at: "",
  } as IGame);

  const [isLoading, setIsLoading] = useState(false);
  const [platforms, setPlatforms] = useState([] as IPlatformOption[]);

  const { setBackgroundImage } = useOutletContext<BackgroundImageOutletContext>();

  useEffect(() => {
    fetchGame();
    fetchPlatforms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchGame = async () => {
    try {
      setIsLoading(true);
      const response = await GameService.getGameByID(id);
      const gameData = response.data;
      setGame(gameData);
      setGameImageBackground(gameData, setBackgroundImage);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleResponseError(error, fecthErrorMessage);
    }
  };

  const fetchPlatforms = async () => {
    try {
      const res = await GameService.getPlatforms();
      setPlatforms(res.data);
    } catch (error) {
      handleResponseError(error, errorSelectOptions);
    }
  };

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  return (
    <>
      {isLoading ? (
        <div className="spinner-grow" role="status" />
      ) : (
        <>
          <UserGame
            action="VIEW"
            gameId={id}
            game={game}
            setGame={setGame}
            platforms={platforms}
          />
        </>
      )}
    </>
  );
};

export default ViewGamePage;
