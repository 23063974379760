import { IGame, IRelatedGame } from "../../types/Game";
import VideogameAssetOutlinedIcon from '@mui/icons-material/VideogameAssetOutlined';
import { dateYear } from "../../utils/Formatter";
import { Chip } from "@mui/material";


type Props = {
    game: IRelatedGame;
};


const BasicCardDisplay = (props: Props) => {

    return (
        <>
            <div className="title mb-2">
                {props.game.game_title} <span className="release_date">({dateYear(props.game.game_release)})</span>
            </div>
        </>
    );
};

export default BasicCardDisplay;